const AboutPage = () => {
  return (
    <div>
      <div className="mb-3">
        My name is Milo. I was born in China and grew up in Canada and the US.
      </div>
      <div className="mb-3">
        Since 2016, I moved back to China as a digital nomad. During my time in
        China, I've experienced firsthand the difficulties foreigners faced
        while traveling in China.
      </div>
      <div className="mb-3">
        This website was built with the motive of providing a comprehensive
        guide for travellers looking to explore China.
      </div>
      <div className="mb-3">
        Due to my personal love for adventures, my hope is that this website
        serves as a starting point but not the end for your adventures. The
        contents I covered are simply the tip of the iceberg and there are
        countless more waiting to be discovered.
      </div>
      <div className="mb-3">
        If you find amazing places I left out, you can let me know
        <a href="mailto:wangmilo@hotmail.com"> here</a>.
      </div>
      <div className="mb-3">
        I received a lot of help from friends. You know who you are.
      </div>
      <div className="mb-3">Stay safe and have fun.</div>
    </div>
  );
};
export default AboutPage;
