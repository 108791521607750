import React from "react";
import { Link } from "react-router-dom";
import ChinaMap from "../MapProvinces/ChinaMap";

const SubmenuRegions = () => {
  const regions = [
    {
      name: "Yunnan",
      provinces: [
        {
          name: "Shangri-La",
          chineseName: "香格里拉",
          link: "/city/shangri-la",
        },
        { name: "Lijiaing", chineseName: "丽江", link: "/city/lijiang" },
        { name: "Dali", chineseName: "大理", link: "/city/dali" },
        { name: "Kunming", chineseName: "昆明", link: "/city/kunming" },
      ],
      color: "border-purple-300",
    },
    {
      name: "Yangtze River Delta",
      provinces: [
        { name: "Shanghai", chineseName: "上海" },
        { name: "Suzhou", chineseName: "苏州" },
        { name: "Hangzhou", chineseName: "杭州" },
      ],
      color: "border-yellow-300",
    },
    {
      name: "Pearl River Delta",
      provinces: [
        { name: "Hong Kong", chineseName: "香港" },
        { name: "Shenzhen", chineseName: "深圳" },
        { name: "Guangzhou", chineseName: "广州" },
      ],
      color: "border-green-300",
    },
    {
      name: "Southwestern China",
      provinces: [
        { name: "Sichuan", chineseName: "四川" },
        { name: "Yunnan", chineseName: "云南" },
        { name: "Guizhou", chineseName: "贵州" },
      ],
      color: "border-blue-300",
    },
    {
      name: "The Silk Road",
      provinces: [
        { name: "Xi'an", chineseName: "西安" },
        { name: "Lanzhou", chineseName: "兰州" },
        { name: "Turfan", chineseName: "吐鲁番" },
        { name: "Urumqi", chineseName: "乌鲁木齐" },
        { name: "Kashgar", chineseName: "喀什" },
      ],
      color: "border-purple-300",
    },
    {
      name: "The Yellow River",
      provinces: [
        { name: "Zhongwei", chineseName: "中卫" },
        { name: "Inner Mongolia", chineseName: "内蒙古" },
      ],
      color: "border-yellow-300",
    },
  ];

  return (
    <div className="grid grid-cols-6 gap-6">
      {regions.map((region, index) => (
        <div key={index} className={`border-l-4 ${region.color} pl-3`}>
          <h3 className="mb-2 font-bold">{region.name}</h3>
          <ul>
            {region.provinces.map((province, prefIndex) => (
              <li key={prefIndex} className="mb-1 text-sm text-gray-600">
                <Link to={province.link}>
                  {province.name} ({province.chineseName})
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="col-span-2">
        <ChinaMap showProvinces={false} />
      </div>
    </div>
  );
};

export default SubmenuRegions;
