import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const MapSingleCounty = ({ countyData }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    let chart;

    const initializeMap = () => {
      if (chartRef.current) {
        // Initialize ECharts instance
        chart = echarts.init(chartRef.current);

        // Register the custom map
        echarts.registerMap("CUSTOM_MAP", countyData);

        // Specify chart configuration
        const options = {
          title: {
            text: "County Map",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b}",
          },
          visualMap: {
            min: 0,
            max: 100,
            text: ["High", "Low"],
            realtime: false,
            calculable: true,
            inRange: {
              color: ["lightskyblue", "yellow", "orangered"],
            },
          },
          series: [
            {
              name: "County",
              type: "map",
              map: "CUSTOM_MAP",
              roam: false,
              data: countyData.features.map((feature) => ({
                name: feature.properties.name,
                value: Math.random() * 100, // Replace with actual data if available
              })),
              label: {
                show: true,
                formatter: "{b}",
                fontSize: 10,
              },
              emphasis: {
                label: {
                  show: true,
                },
              },
            },
          ],
        };

        // Set the chart options
        chart.setOption(options);
      }
    };

    initializeMap();

    // Cleanup function
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        const chart = echarts.getInstanceByDom(chartRef.current);
        if (chart) {
          chart.resize();
        }
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div ref={chartRef} style={{ width: "100%", height: "600px" }} />;
};

export default MapSingleCounty;
