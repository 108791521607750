import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import iconAlipay from "../../assets/images/icon_alipay.png";
import iconWechat from "../../assets/images/icon_wechat.png";
import bannerImg from "../../assets/images/shangri-la-songzanlinsi2.jpg";
import Breadcrumb from "../../components/Breadcrumb";
import PageTemplate3 from "../../components/PageTemplate3";
import { useState } from "react";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Planning", path: "/planning" },
  { label: "Money", path: "/planning/money" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#overview");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#overview"
          className={`${
            activeLink === "#overview" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#overview")}
        >
          Overview
        </a>
      </li>
      <li>
        <a
          href="#alipay"
          className={`${
            activeLink === "#alipay" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#alipay")}
        >
          Alipay
        </a>
      </li>
      <li>
        <a
          href="#wechat"
          className={`${
            activeLink === "#wechat" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#wechat")}
        >
          Wechat
        </a>
      </li>
      <li className="mb-4">
        <a
          href="#cash"
          className={`${activeLink === "#cash" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#cash")}
        >
          Cash
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>Language Barrier - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h1 className="mt-8 text-4xl font-extrabold">Language Barrier</h1>
      <h2 id="overview" className="mt-8 text-3xl font-extrabold">
        Overview
      </h2>
      <div>
        The language barrier is something you'll encounter while traveling in
        China. Here are some effective strategies to overcome the language
        barrier while traveling in China
      </div>

      <h2 className="mb-6 text-3xl font-semibold">Use Translation Apps</h2>
      <div>
        <strong>Google Translate:</strong> Although Google services are
        restricted in China, you can still use Google Translate offline by
        downloading the Chinese language pack before you arrive. The app allows
        you to translate text, speech, and even images (like signs or menus).
      </div>
      <div>
        <strong>iPhone's Translate App:</strong> If you use an iPhone, you can
        use Apple's Translate app. This app isn't restricted in China.
      </div>
      <div>
        <strong>Microsoft Translator:</strong>This app works well in China and
        supports offline translation, voice translation, and real-time
        conversation translation.
      </div>

      <h2>Basic Phrases</h2>
      <div>
        Here are some basic Chinese phrases to know for your trip:
        <ul>
          <li>Nǐ hǎo (Nee how) – Hello</li>
          <li>Nǐ hǎo ma? (Nee-haoww-mah?) – How are you?</li>

          <li>Xiè Xie (shyeah-shyeah) – Thank you!</li>

          <li>Bù kèqì (Boo kuh-chi) – You’re welcome</li>

          <li>Bù (Boo) – No</li>

          <li>Duō shǎo qian? (Dwuh shao chian?) – How much?</li>

          <li>Măi dān (My dahn) – Check, please.</li>

          <li>Cèsuǒ (tser-swor) – Restroom</li>

          <li>Hào chī (How chir) – Delicious</li>

          <li>... zài nǎlǐ? (… ziai na-lee?) – Where is…?</li>

          <li>Ganbei (gan bay) – Cheers</li>

          <li>Pijiu (pee-joo) – Beer</li>
        </ul>
      </div>
    </>
  );
};

const PageLanguageBarrier = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageLanguageBarrier;
