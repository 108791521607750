import React, { useState } from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { geoJson } from "../data/provinces_geojson";

const ChinaLeafletMap = () => {
  // Default style for provinces
  const provinceStyle = {
    fillColor: "purple",
    weight: 2,
    opacity: 1,
    color: "white", // Province border color
    fillOpacity: 0.2,
  };

  // State for the hovered province
  const [hoveredProvince, setHoveredProvince] = useState(null);

  // Mouseover event to highlight province
  const handleMouseOver = (event) => {
    const layer = event.target;
    layer.setStyle({
      fillColor: "orange", // Highlight color
      weight: 2,
      color: "black",
      fillOpacity: 1,
    });
    setHoveredProvince(layer.feature.properties.name); // Assuming province name is under `properties.name`
  };

  // Mouseout event to reset province style
  const handleMouseOut = (event) => {
    const layer = event.target;
    layer.setStyle(provinceStyle);
    setHoveredProvince(null); // Remove highlight
  };

  // Function to attach event handlers to each province
  const onEachProvince = (province, layer) => {
    layer.on({
      mouseover: handleMouseOver,
      mouseout: handleMouseOut,
    });
  };

  return (
    <div>
      <MapContainer
        center={[35.8617, 104.1954]} // Centered on China
        zoom={4}
        style={{ height: "100vh", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
        />
        {/* <TileLayer
          url="https://{s}.tile.stamen.com/toner-lite/{z}/{x}/{y}.png"
          attribution='Map tiles by <a href="https://stamen.com">Stamen Design</a>, under <a href="https://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="https://openstreetmap.org">OpenStreetMap</a>, under ODbL.'
        /> */}
        {/* <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        /> */}
        {/* GeoJSON layer for China's provinces */}
        <GeoJSON
          data={geoJson}
          style={provinceStyle}
          onEachFeature={onEachProvince}
        />
      </MapContainer>
      {/* Display the name of the hovered province */}
      {hoveredProvince && (
        <div
          className="hovered-province"
          style={{
            position: "absolute",
            top: 10,
            left: 10,
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          Hovered Province: {hoveredProvince}
        </div>
      )}
    </div>
  );
};

export default ChinaLeafletMap;
