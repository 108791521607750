import React, { useState } from "react";
import { Check, Copy } from "lucide-react";

const ClickToCopy = ({ text, moreClasses = "" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <span
      className={`inline items-center space-x-2 rounded bg-gray-100 p-2 ${moreClasses}`}
    >
      <span className="flex-grow">{text}</span>
      <button
        onClick={handleCopy}
        className="rounded p-1 transition-colors hover:bg-gray-200"
        aria-label={isCopied ? "Copied!" : "Copy to clipboard"}
      >
        {isCopied ? (
          <Check className="pt-1 text-green-600" size={20} />
        ) : (
          <Copy size={20} className="pt-1" />
        )}
      </button>
    </span>
  );
};

export default ClickToCopy;
