import React, { useState, useEffect, useRef } from "react";
import { Search } from "lucide-react";
import MegaMenu from "./MegaMenu";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div>
      <header className="relative z-50">
        <div className="container mx-auto flex items-center justify-between px-16 pt-3">
          <div className="flex">
            <div
              id="header-logo"
              className="text-2xl font-bold"
              style={{ marginTop: 2 }}
            >
              <Link to="/">
                <span className="text-pink-600">super</span>chinaguide.com
              </Link>
            </div>
            <MegaMenu />
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
