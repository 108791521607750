import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import iconAlipay from "../../assets/images/icon_alipay.png";
import iconWechat from "../../assets/images/icon_wechat.png";
import bannerImg from "../../assets/images/shangri-la-songzanlinsi2.jpg";
import Breadcrumb from "../../components/Breadcrumb";
import PageTemplate3 from "../../components/PageTemplate3";
import { useState } from "react";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Planning", path: "/planning" },
  { label: "Money", path: "/planning/money" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#overview");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#overview"
          className={`${
            activeLink === "#overview" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#overview")}
        >
          Overview
        </a>
      </li>
      <li>
        <a
          href="#alipay"
          className={`${
            activeLink === "#alipay" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#alipay")}
        >
          Alipay
        </a>
      </li>
      <li>
        <a
          href="#wechat"
          className={`${
            activeLink === "#wechat" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#wechat")}
        >
          Wechat
        </a>
      </li>
      <li className="mb-4">
        <a
          href="#cash"
          className={`${activeLink === "#cash" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#cash")}
        >
          Cash
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>Money in China - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h1 className="mt-8 text-4xl font-extrabold">Money in China</h1>
      <h2 id="overview" className="mt-8 text-3xl font-extrabold">
        Overview
      </h2>
      <div className="my-4">
        It's hard to get around China without mobile payments. Cash is rarely
        used nowadays and two mobile apps: <b>Alipay</b> and <b>Wechat</b> are
        used to handle transactions.
        <div className="mt-4 flex items-center space-x-4">
          <img src={iconAlipay} width={80} alt="Alipay icon" />
          <img src={iconWechat} width={50} alt="Wechat icon" />
        </div>
      </div>

      <h2 id="alipay" className="mt-12 text-3xl font-extrabold">
        Alipay
      </h2>
      <div className="my-4">
        <div>
          Alipay allows linking to Visa debit/credit cards without the need for
          a Chinese phone number.
        </div>
        <div>
          You can make payments by either:
          <ol className="list-inside list-decimal space-y-2">
            <li>Scanning a QR code with Alipay, or</li>
            <li>
              Opening a QR code within your Alipay app and let the cashier scan
              your QR code.
            </li>
          </ol>
        </div>
        <h2 className="mb-4 mt-4 text-2xl font-semibold">How to Use Alipay</h2>
        <ol className="list-inside list-decimal space-y-4 text-lg">
          <li>
            <span className="font-medium">Download Alipay:</span> Available on
            Android and iOS.
          </li>
          <li>
            <span className="font-medium">Register Your Account:</span> Use your
            phone number and passport to verify your identity.
          </li>
          <li>
            <span className="font-medium">Link a Bank Card:</span> Alipay
            supports foreign cards, but availability might vary.
          </li>
          <li>
            <span className="font-medium">International Version:</span> Alipay
            also offers an international version that caters specifically to
            tourists, allowing them to load money and pay without a Chinese bank
            account.
          </li>
        </ol>
      </div>

      <div id="wechat">
        <h2 className="mt-12 text-3xl font-extrabold">Wechat Pay</h2>

        <h2 className="mb-4 mt-4 text-2xl font-semibold">
          How to Use WeChat Pay
        </h2>
        <ol className="list-inside list-decimal space-y-4 text-lg">
          <li>
            <span className="font-medium">Download WeChat:</span> Available on
            both Android and iOS.
          </li>
          <li>
            <span className="font-medium">Link Your Bank Card:</span> WeChat now
            allows foreign bank cards to be linked, though some restrictions may
            apply depending on your card issuer.
          </li>
          <li>
            <span className="font-medium">Top Up Your Wallet:</span> You can top
            up your WeChat Wallet using your linked bank card. Some
            international cards may also work for direct payments without
            topping up.
          </li>
        </ol>
      </div>

      <div id="cash">
        <h2 className="mt-12 text-3xl font-extrabold">Cash</h2>
        <div>
          Despite the prevalence of mobile payments, cash remains a viable
          option. The Chinese Yuan (CNY) is the official currency.
        </div>
        <div>
          Tips for Using Cash:
          <ol>
            <li>
              Exchange Money: You can exchange foreign currency at banks,
              hotels, or official exchange centers.
            </li>
            <li>
              Carry Small Denominations: Always have small bills on hand for
              taxis, street food, or small shops.
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

const PageMoney = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageMoney;
