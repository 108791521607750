import React from "react";
import beijingImg from "../../assets/images/tiananmen1.jpg";
import chengduImg from "../../assets/images/chengdu2.jpg";
import chongqingImg from "../../assets/images/chongqing1.jpg";
import hongkongImg from "../../assets/images/hongkong1.jpg";
import lijiangImg from "../../assets/images/lijiang1.jpg";
import shanghaiImg from "../../assets/images/shanghai2.jpg";
import xianImg from "../../assets/images/xian1.jpg";
import yangshuoImg from "../../assets/images/yangshuo1.jpg";
import { Link } from "react-router-dom";

export const topDestinations = [
  {
    name: "Shanghai",
    chineseName: "上海",
    img: shanghaiImg,
  },
  {
    name: "Beijing",
    chineseName: "北京",
    img: beijingImg,
  },
  {
    name: "Xi'an",
    chineseName: "西安",
    img: xianImg,
  },
  {
    name: "Chengdu",
    chineseName: "成都",
    img: chengduImg,
  },
  {
    name: "Chongqing",
    chineseName: "重庆",
    img: chongqingImg,
  },
  {
    name: "Yangshuo",
    chineseName: "阳朔",
    img: yangshuoImg,
  },
  {
    name: "Lijiang",
    chineseName: "丽江",
    img: lijiangImg,
  },
  {
    name: "Hong Kong",
    chineseName: "香港",
    img: hongkongImg,
  },
];

const TopDestinationCard = ({ destination }) => {
  return (
    <div key={destination.name} className="relative rounded-lg border p-4">
      <Link to={`/city/${destination.name}`}>
        <img
          src={`${destination.img}`}
          alt={destination.name}
          className="h-auto w-full rounded-lg"
        />
      </Link>
      <div className="absolute bottom-0 left-0 right-0 p-6 text-center text-white">
        <h3 className="text-lg font-semibold">{destination.name}</h3>
        {/* <p className="text-sm">{destination.chineseName}</p> */}
      </div>
    </div>
  );
};

const SubmenuTopDestinations = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {topDestinations.map((destination) => (
          <TopDestinationCard destination={destination} />
        ))}
      </div>
      <div className="mt-4">
        <Link>View all cities</Link>
      </div>
    </div>
  );
};

export default SubmenuTopDestinations;
