import React, { useState, useEffect, useRef } from "react";
import MenuDestinations from "./MenuDestinations/MenuDestinations";
import MenuInterests from "./MenuInterests/MenuInterests";
import MenuShop from "./MenuShop";
import MenuPlanning from "./MenuPlanning";
import MenuRegions from "./MenuRegions/MenuRegions";
import { Link } from "react-router-dom";

const MegaMenu = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);

  const cls =
    "absolute left-0 z-10 mt-2 w-screen max-w-6xl rounded-lg bg-white shadow-lg";
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  return (
    <nav id="mega-menu" ref={menuRef}>
      <ul className="my-2 ml-10 flex">
        <li key={0} className="relative">
          <button
            onClick={() => toggleMenu(0)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 0 ? "menu-active" : ""
            }`}
          >
            Destinations
          </button>
          {activeMenu === 0 && (
            <div style={{ marginLeft: -200 }} className={cls}>
              <MenuDestinations />
            </div>
          )}
        </li>
        <li key={4} className="relative">
          <button
            onClick={() => toggleMenu(4)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 4 ? "menu-active" : ""
            }`}
          >
            Regions
          </button>
          {activeMenu === 4 && (
            <div style={{ marginLeft: -280 }} className={cls}>
              <MenuRegions />
            </div>
          )}
        </li>
        <li key={5} className="relative">
          <button
            onClick={() => toggleMenu(5)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 4 ? "menu-active" : ""
            }`}
          >
            Routes
          </button>
          {activeMenu === 5 && (
            <div style={{ marginLeft: -280 }} className={cls}>
              <MenuRegions />
            </div>
          )}
        </li>
        <li key={1} className="relative">
          <button
            onClick={() => toggleMenu(1)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 1 ? "menu-active" : ""
            }`}
          >
            Interests
          </button>
          {activeMenu === 1 && (
            <div className={`${cls} right-0 mx-auto`}>
              <MenuInterests />
            </div>
          )}
        </li>
        <li key={2} className="relative">
          <button
            onClick={() => toggleMenu(2)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 2 ? "menu-active" : ""
            }`}
          >
            Planning
          </button>
          {activeMenu === 2 && (
            <div style={{ marginLeft: -200 }} className={`${cls}`}>
              <MenuPlanning />
            </div>
          )}
        </li>
        <li key={3} className="relative">
          <button
            onClick={() => toggleMenu(3)}
            className={`block px-4 focus:outline-none ${
              activeMenu === 3 ? "menu-active" : ""
            }`}
          >
            Shop
          </button>
          {activeMenu === 3 && (
            <div style={{ marginLeft: -200 }} className={cls}>
              <MenuShop />
            </div>
          )}
        </li>

        <li key={6} className="relative">
          <Link to="/map" className="px-4">
            Map
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default MegaMenu;
