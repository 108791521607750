import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const PageTemplate3 = ({
  bannerImg,
  topContent,
  leftSidebar,
  mainContent,
  rightSidebarContent,
}) => {
  return (
    <div>
      <Header />

      {/* Main Content */}
      <main className="relative">
        {topContent}

        {/* Hero Image - Full Width */}
        {bannerImg && (
          <div className="relative mt-4 h-96 w-full">
            <div className="absolute inset-0">
              <img
                src={bannerImg}
                alt=""
                className="h-full w-full object-cover"
              />
            </div>
          </div>
        )}

        {/* <div className="mx-auto px-4">{content}</div> */}
        <div className="flex">
          <div className="w-1/6 p-3">{leftSidebar}</div>
          <div className="w-3/5 p-3">{mainContent}</div>
          <div className="ml-3 w-1/6 border-l">
            <RightSidebar rightSidebarContent={rightSidebarContent} />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

const RightSidebar = ({ rightSidebarContent }) => {
  return <nav className="sticky flex-col pl-4 pt-3">{rightSidebarContent}</nav>;
};

export default PageTemplate3;
