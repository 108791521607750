import React, { useState } from "react";
import SubmenuRegions from "../MenuDestinations/SubmenuRegions";
import SubmenuTopDestinations from "../MenuDestinations/SubmenuTopDestinations";
import SubmenuNature from "./SubmenuNature";
import SubmenuCities from "./SubmenuCities";

const MenuInterests = () => {
  const [activeSubmenu, setActiveSubmenu] = useState("nature");

  const handleMouseEnter = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleMouseLeave = () => {
    // setActiveSubmenu(null);
  };

  return (
    <div
      className="block rounded-lg bg-white p-6 shadow-md"
      onMouseLeave={handleMouseLeave}
    >
      <div className="mb-4 flex items-center justify-between">
        <div id="nav-items" className="flex space-x-4">
          <span
            className={`cursor-pointer px-2 ${activeSubmenu === "nature" ? "submenu-active" : ""}`}
            onMouseEnter={() => handleMouseEnter("nature")}
          >
            Nature
          </span>
          <span
            className={`cursor-pointer px-2 ${activeSubmenu === "historical-sites" ? "submenu-active" : ""}`}
            onMouseEnter={() => handleMouseEnter("historical-sites")}
          >
            Historical Sites
          </span>
          <span
            className={`cursor-pointer px-2 ${activeSubmenu === "urban" ? "submenu-active" : ""}`}
            onMouseEnter={() => handleMouseEnter("urban")}
          >
            Urban Cities
          </span>
        </div>
      </div>
      {activeSubmenu === "nature" && <SubmenuNature />}
      {activeSubmenu === "historical-sites" && <SubmenuRegions />}
      {activeSubmenu === "urban" && <SubmenuCities />}
    </div>
  );
};

export default MenuInterests;
