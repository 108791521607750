import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";
import { useNavigate } from "react-router-dom";
import { chinaMapConfig } from "./config";
import { geoJson } from "../../data/provinces_geojson";
import { addonData } from "../../data/provinces_addon_data2";
import { useHover } from "../../hooks/HoverContext";

const defaultStyle = {
  width: "100%",
  height: "99vh",
};

const ChinaMap = ({ style = defaultStyle, showProvinces = true }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { hoveredProvince } = useHover();
  let mapInstance = null;

  const renderMap = () => {
    const renderedMapInstance = echarts.getInstanceByDom(ref.current);
    if (renderedMapInstance) {
      mapInstance = renderedMapInstance;
    } else {
      mapInstance = echarts.init(ref.current);
    }
    mapInstance.setOption(
      chinaMapConfig({
        backgroundColor: "transparent",
        data: addonData.data,
        // max: addonData.max,
        // min: 0,
        showLabels: showProvinces,
      }),
    );

    mapInstance.off("click"); // Ensure only one event listener is attached
    mapInstance.on("click", function (params) {
      navigate(`/province/${params.name}`);
    });

    // Add hover event handling
    mapInstance.off("mouseover");
    mapInstance.off("mouseout");

    mapInstance.on("mouseover", function (params) {
      // Handle mouseover event (e.g., change style, show tooltip)
      console.log("Hovered on area:", params);
      mapInstance.dispatchAction({
        type: "highlight",
        // seriesIndex: 0,
        // dataIndex: params.dataIndex,
        name: params.name,
      });
    });

    mapInstance.on("mouseout", function (params) {
      // Handle mouseout event (e.g., reset style, hide tooltip)
      console.log("Mouseout on area:", params);
      mapInstance.dispatchAction({
        type: "downplay",
        // seriesIndex: 0,
      });
    });
  };

  useEffect(() => {
    echarts.registerMap("china", { geoJSON: geoJson });
    renderMap();
  }, [renderMap]);

  useEffect(() => {
    window.onresize = function () {
      mapInstance.resize();
    };
    return () => {
      mapInstance && mapInstance.dispose();
    };
  }, [mapInstance]);

  useEffect(() => {
    if (mapInstance) {
      if (hoveredProvince) {
        // console.log("!!!hoveredProvince", hoveredProvince);
        mapInstance.dispatchAction({
          type: "highlight",
          name: hoveredProvince,
        });
      } else {
        mapInstance.dispatchAction({
          type: "downplay",
        });
      }
    }
  }, [mapInstance, hoveredProvince]);

  return (
    <div>
      <div style={defaultStyle} ref={ref}></div>
    </div>
  );
};
export default ChinaMap;
