export const chinaMapConfig = (configData) => {
  const { data, showLabels, titleText } = configData;

  return {
    title: {
      text: titleText,
      left: "right",
      textStyle: {
        color: "#000",
      },
    },
    toolbox: {
      show: true,
      left: "left",
      top: "top",
      feature: {
        // Additional toolbox features can be added here
      },
    },
    dataset: {
      source: data,
    },
    series: {
      name: "Terrain",
      type: "map",
      map: "china",
      roam: false,
      geoIndex: 0,
      label: {
        show: showLabels,
        position: [1, 100],
        fontSize: 13,
        offset: [2, 0],
        align: "left",
      },
      itemStyle: {
        areaColor: "#fff", // Default map area color
        emphasis: {
          areaColor: "#FF5722", // Highlight color on hover
        },
      },
      zoom: 1.2,
      scaleLimit: {
        max: 1.2,
        min: 1.2,
      },
      top: "10%",
    },
  };
};
