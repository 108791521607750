export const addonData = {
  data: [
    {
      name: "Beijing",
      chineseName: "北京",
    },
    {
      name: "Guangdong",
      chineseName: "广东",
    },
    {
      name: "Taiwan",
      chineseName: "台湾",
    },
    {
      name: "Hong Kong",
      chineseName: "香港",
    },
    {
      name: "Shandong",
      chineseName: "山东",
    },
    {
      name: "Jiangsu",
      chineseName: "江苏",
    },
    {
      name: "Zhejiang",
      chineseName: "浙江",
    },
    {
      name: "Shanghai",
      chineseName: "上海",
    },
    {
      name: "Hebei",
      chineseName: "河北",
    },
    {
      name: "Henan",
      chineseName: "河南",
    },
    {
      name: "Liaoning",
      chineseName: "辽宁",
    },
    {
      name: "Sichuan",
      chineseName: "四川",
    },
    {
      name: "Hubei",
      chineseName: "湖北",
    },
    {
      name: "Hunan",
      chineseName: "湖南",
    },
    {
      name: "Anhui",
      chineseName: "安徽",
    },
    {
      name: "Jilin",
      chineseName: "吉林",
    },
    {
      name: "Jiangxi",
      chineseName: "江西",
    },
    {
      name: "Xinjiang",
      chineseName: "新疆",
    },
    {
      name: "Chongqing",
      chineseName: "重庆",
    },
    {
      name: "Fujian",
      chineseName: "福建",
    },
    {
      name: "Guangxi",
      chineseName: "广西",
    },
    {
      name: "Shanxi",
      chineseName: "山西",
    },
    {
      name: "Yunnan",
      chineseName: "云南",
    },
    {
      name: "Inner Mongolia",
      chineseName: "内蒙古",
    },
    {
      name: "Heilongjiang",
      chineseName: "黑龙江",
    },
    {
      name: "Shaanxi",
      chineseName: "陕西",
    },
    {
      name: "Tianjin",
      chineseName: "天津",
    },
    {
      name: "Ningxia",
      chineseName: "宁夏",
    },
    {
      name: "Gansu",
      chineseName: "甘肃",
    },
    {
      name: "Guizhou",
      chineseName: "贵州",
    },
    {
      name: "Tibet",
      chineseName: "西藏",
    },
    {
      name: "Qinghai",
      chineseName: "青海",
    },
    {
      name: "Hainan",
      chineseName: "海南",
    },
  ],
};
