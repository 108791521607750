import { Helmet } from "react-helmet";
import AttractionCard from "../../../components/AttractionCard";
import bannerImg from "../../../assets/images/shanghai-banner.jpg";
import bundImg from "../../../assets/images/shanghai2.jpg";
import nanjingroadImg from "../../../assets/images/shanghai-nanjingroad.avif";
import xintiandiImg from "../../../assets/images/shanghai-xintiandi.avif";
import fazujieImg from "../../../assets/images/shanghai-fazujie.jpg";
import sinanImg from "../../../assets/images/shanghai-sinan.jpg";
import disneylandImg from "../../../assets/images/shanghai-disneyland.avif";
import wukangImg from "../../../assets/images/shanghai-wukang.jpeg";
import airportMapImg from "../../../assets/images/shanghai-airport-map.jpg";
import shanghaiMuseumImg from "../../../assets/images/shanghai-museum.jpg";
import chinaArtMuseumImg from "../../../assets/images/shanghai-china-art-museum.jpeg";
import Breadcrumb from "../../../components/Breadcrumb";
import PageTemplate3 from "../../../components/PageTemplate3";
import { useState } from "react";
import { Clock, MapPin } from "lucide-react";
import ClickToCopy from "../../../components/ClickToCopy";
import ShanghaiLeafletMap from "../../../components/ShanghaiLeafletMap";
import ChinaLeafletMap from "../../../components/ChinaLeafletMap";
const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Shanghai", path: "/city/shanghai" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#about");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#about"
          className={`${
            activeLink === "#about" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#about")}
        >
          About Shanghai
        </a>
      </li>
      <li>
        <a
          href="#attractions"
          className={`${
            activeLink === "#attractions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#attractions")}
        >
          Top Attractions
        </a>
      </li>
      <li>
        <a
          href="#directions"
          className={`${
            activeLink === "#directions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#directions")}
        >
          Getting There
        </a>
      </li>
      <li>
        <a
          href="#3day"
          className={`${activeLink === "#3day" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#3day")}
        >
          3-Day Itinerary
        </a>
      </li>
      <li>
        <a
          href="#districts"
          className={`${activeLink === "#districts" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#districts")}
        >
          Districts
        </a>
      </li>

      <li>
        <a
          href="#jing-an"
          className={`${activeLink === "#jing-an" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#jing-an")}
        >
          Jing'an
        </a>
      </li>
      <li>
        <a
          href="#xuhui"
          className={`${activeLink === "#xuhui" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#xuhui")}
        >
          Xuhui
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>Shanghai - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h2 id="about" className="mt-8 text-3xl font-extrabold">
        About Shanghai
      </h2>
      <ShanghaiLeafletMap />
      {/* <MapSingleCounty countyData={shanghaiData} />
      <MapShanghai /> */}
      <div className="my-4">
        As China's most approachable city for foreigners, Shanghai offers a
        unique blend of Eastern and Western influences.
        <br />
        <br />
        With monikers like "Pearl of the Orient", "Paris of the East" and "魔都"
        (magic capital), Shanghai is a luxurious megacity and China's financial
        hub.
        <br />
        <br />
        The city's well-developed infrastructure, English-friendly environment,
        and thriving business scene make it a popular destination for those
        seeking to experience Chinese culture while enjoying familiar comforts.
      </div>

      <h2 id="attractions" className="mt-12 text-3xl font-extrabold">
        Top Attractions
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            link="/attraction/nanjingroad"
            name="Nanjing Road"
            desc={
              <div>
                One of the world’s busiest shopping streets, filled with malls
                and restaurants.
              </div>
            }
            img={nanjingroadImg}
          />
          <AttractionCard
            link="/attraction/thebund"
            name="The Bund"
            desc={
              <div>
                Shanghai's famous waterfront, lined with colonial-era buildings
                and stunning views of the modern skyline.
              </div>
            }
            img={bundImg}
          />
          <AttractionCard
            name="Xintiandi"
            desc={
              <div>
                A trendy district featuring upscale restaurants, cafes, and
                shopping. Located within historic Shikumen houses.
              </div>
            }
            img={xintiandiImg}
          />
          <AttractionCard
            name="Disneyland"
            desc={<div>The first Disney theme park in mainland China.</div>}
            img={disneylandImg}
          />
          <AttractionCard
            name="Former French Concession"
            desc={
              <div>
                A charming district in the heart of Shanghai offering a unique
                blend of European elegance and Chinese culture.
              </div>
            }
            img={fazujieImg}
          />
          <AttractionCard
            name={`INS Fuxing / Fuxing Park`}
            desc={
              <div>
                Possibly the most popular nightlife spot for young people in
                Shanghai.
              </div>
            }
            img={sinanImg}
          />
          <AttractionCard
            name="Wukang Road"
            desc={
              <div>
                Over 100 years ago, back when it was called Ferguson Lane,
                prominent figures from all over the world flocked to this
                tree-lined street.
              </div>
            }
            img={wukangImg}
          />
          <AttractionCard
            name="Sinan Mansions"
            desc={
              <div>
                Early 20th-century villas now transformed into a vibrant hub of
                boutique shops, fine dining and art galleries.
              </div>
            }
            img={sinanImg}
          />
        </div>
      </div>

      <h2 id="attractions" className="mt-12 text-3xl font-extrabold">
        Food
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            link="/attraction/thebund"
            name="The Bund"
            desc={
              <div>
                Shanghai's famous waterfront, lined with colonial-era buildings
                and stunning views of the modern skyline.
              </div>
            }
            img={bundImg}
          />
        </div>
      </div>

      <h2 id="museums" className="mt-12 text-3xl font-extrabold">
        Museums
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            link="/attraction/shanghai-museum"
            name="Shanghai Museum"
            desc={
              <div>
                Shanghai museum exhibits a large collection of ancient Chinese
                art.
                <br />
                <br />
                <MapPin size={15} className="inline" /> People's Square
                <br />
                <Clock size={13} className="inline" /> Closed on Tuesdays.
              </div>
            }
            img={shanghaiMuseumImg}
          />
          <AttractionCard
            link="/attraction/shanghai-china-art-museum"
            name="China Art Museum"
            desc={
              <div>
                TODO
                <br />
                <br />
                <MapPin size={15} className="inline" /> TODO location
                <br />
                <Clock size={13} className="inline" /> Closed on Tuesdays.
              </div>
            }
            img={chinaArtMuseumImg}
          />
          <AttractionCard
            link="/attraction/shanghai-natural-history-museum"
            name="Shanghai Natural History Museum"
            desc={
              <div>
                TODO
                <br />
                <br />
                <MapPin size={15} className="inline" /> TODO location
                <br />
                <Clock size={13} className="inline" /> 9am - 5pm. Closed on
                Mondays.
              </div>
            }
            img={chinaArtMuseumImg}
          />
        </div>
      </div>

      <div id="directions">
        <h2 className="mt-12 text-3xl font-extrabold">Getting There</h2>
      </div>
      <div className="my-4 text-xl font-bold">By Air</div>
      <div>
        Shanghai has two international airports:
        <ul>
          <li>
            - Pudong International Airport (PVG) -{" "}
            <ClickToCopy text="浦东国际机场" />
          </li>
          <li>
            - Hongqiao International Airport (SHA) -{" "}
            <ClickToCopy text="虹桥国际机场" />
          </li>
        </ul>
        <br />
        <img
          src={airportMapImg}
          alt="airport location map"
          style={{ width: 500 }}
        />
        <br />
        Most international flights to Shanghai land at Pudong International
        Airport, which is located to the east of the city and takes about an
        hour and a half drive from the city center when there's no traffic.
        <br />
        Hongqiao is located at the west of Shanghai and also takes about an hour
        drive from the city center. Hongqiao offers domestic flights to other
        cities in China.
      </div>

      <div className="my-4 text-xl font-bold">
        Getting to/from Pudong Airport
      </div>
      <div>
        You can take the subway to Pudong International Airport. Take Line 2 all
        the way to the end and you'll arrive at Pudong International Airport
        station.
      </div>

      <div className="my-4 text-xl font-bold">
        Getting to/from Hongqiao Airport
      </div>
      <div>
        You can take the subway to Hongqiao International Airport. Take Line 2
        all the way and get off at Hongqiao Terminal 2 Station.
      </div>

      <div className="my-4 text-xl font-bold">By Train</div>
      <div>
        Shanghai has three railway stations:
        <ul>
          <li>
            - Shanghai Station - <ClickToCopy text="上海站" />
          </li>
          <li>
            - Shanghai South Station - <ClickToCopy text="上海南站" />
          </li>
          <li>
            - Hongqiao Station - <ClickToCopy text="虹桥火车站" />
          </li>
        </ul>
      </div>
      <div id="3day">
        <h2 className="mt-12 text-3xl font-extrabold">3 Day Itinerary</h2>
      </div>
      <div className="my-4 text-xl font-bold">Day 1</div>
      <div>Do this on day one.</div>
      <div className="my-4 text-xl font-bold">Day 2</div>
      <div>Do this on day two.</div>
    </>
  );
};

const PageShanghai = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageShanghai;
