import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AttractionCard from "../../../components/AttractionCard";
import bannerImg from "../../../assets/images/shanghai4.jpg";
import bundImg from "../../../assets/images/shanghai2.jpg";
import shuheImg from "../../../assets/images/lijiang-shuhe1.jpg";
import Breadcrumb from "../../../components/Breadcrumb";
import PageTemplate3 from "../../../components/PageTemplate3";
import { useState } from "react";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Shanghai", path: "/city/shanghai" },
  { label: "The Bund", path: "/attraction/thebund" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#about");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#about"
          className={`${
            activeLink === "#about" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#about")}
        >
          About Shanghai
        </a>
      </li>
      <li>
        <a
          href="#attractions"
          className={`${
            activeLink === "#attractions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#attractions")}
        >
          Top Attractions
        </a>
      </li>
      <li>
        <a
          href="#directions"
          className={`${
            activeLink === "#directions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#directions")}
        >
          Getting There
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>The Bund - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h2
        id="about"
        className="mt-8 text-5xl"
        style={{ fontFamily: "SohneBold" }}
      >
        The Bund (Waitan)
      </h2>
      <div className="my-4">
        bund bund bund
        <br />
        bund bund bund
      </div>

      <div id="directions">
        <h2 className="mt-12 text-3xl font-extrabold">Getting There</h2>
      </div>
      <div className="my-4 font-bold">By Air</div>
      <div>
        Shangri-La can be reached by air, road, or a combination of both. The
        Diqing Shangri-La Airport (DIG) offers direct flights from major Chinese
        cities like Kunming, Chengdu, and Lhasa. The airport is just a short
        drive from the city center. For those preferring overland travel, buses
        and private cars are available from Lijiang, a journey that takes around
        4-5 hours through scenic mountain roads. An alternative is to travel by
        train or bus to Lijiang from Kunming, then continue by road to
        Shangri-La. The journey offers stunning views of the Yunnan countryside,
        making it a memorable part of the adventure.
      </div>
      <div className="my-4 font-bold">Overland Travel</div>
      <div>
        For those preferring overland travel, buses and private cars are
        available from Lijiang, a journey that takes around 4-5 hours through
        scenic mountain roads. An alternative is to travel by train or bus to
        Lijiang from Kunming, then continue by road to Shangri-La. The journey
        offers stunning views of the Yunnan countryside, making it a memorable
        part of the adventure.
      </div>
    </>
  );
};

const PageTheBund = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      //   bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageTheBund;
