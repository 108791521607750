import bannerImg from "../../assets/images/liming-banner.jpg";
import CityHeader from "../../components/CityHeader";
import PageTemplate3 from "../../components/PageTemplate3";

const PageInterests = () => {
  return (
    <div>
      <PageTemplate3
        bannerImg={bannerImg}
        topContent={<TopContent />}
        content={<Content />}
      />
    </div>
  );
};

const TopContent = () => {
  return (
    <CityHeader
      province=""
      city="Interests"
      description="Trad climbing paradise"
      tags={
        <span className="mr-2 rounded bg-red-500 px-2 py-1 text-sm text-white">
          Interest
        </span>
      }
    />
  );
};

const Content = () => {
  return (
    <div>
      <div>Art, Tradition and Culture</div>
      <div>Nature and Outdoors</div>
    </div>
  );
};

export default PageInterests;
