import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

const ShanghaiLeafletMap = () => {
  // Coordinates for Shanghai, China
  const shanghaiCoords = [31.2304, 121.4737];
  const [isCartoLayer, setIsCartoLayer] = useState(true);
  const toggleLayer = () => {
    setIsCartoLayer(!isCartoLayer);
  };
  const tileUrl = isCartoLayer
    ? "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" // Carto Voyager Tile
    : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";

  return (
    <div>
      <MapContainer
        center={shanghaiCoords}
        zoom={12} // Adjust zoom level as needed
        style={{ height: "58vh", width: "100%" }} // Map will fill the whole viewport
      >
        {/* Tile layer for the map. OpenStreetMap is a free tile provider 
      Change .org to .cn for Chinese map */}
        <TileLayer
          url={tileUrl}
          attribution={
            isCartoLayer
              ? '&copy; <a href="https://carto.com/attributions">CARTO</a>'
              : '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        />
        {/* <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      /> */}
        {/* <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      /> */}

        {/* Marker at the center of Shanghai */}
        <Marker position={shanghaiCoords}>
          <Popup>Welcome to Shanghai!</Popup>
        </Marker>
      </MapContainer>
      {/* Button to toggle between layers */}
      <button onClick={toggleLayer} style={{ marginTop: "10px" }}>
        Toggle to {isCartoLayer ? "OpenStreetMap" : "Carto Voyager"}
      </button>
    </div>
  );
};

export default ShanghaiLeafletMap;
