const PlanningPage = () => {
  return (
    <div>
      <ul>
        <li>Visa</li>
        <li>Money</li>
        <li>Internet</li>
        <li>Maps</li>
        <li>Transportation</li>
        <li>Language Barrier</li>
      </ul>

      <div>
        <h2>Maps</h2>
        <ul>
          <li>
            Gaode Maps (高德地图): offers accurate real-time traffic
            information, public transportation routes, and ride-hailing
            services.
          </li>
          <li>Baidu Maps (百度地图): download links</li>
        </ul>
      </div>
      <div>
        <h2>Transportation</h2>
        <div>
          <div>
            <strong>Driving</strong>
          </div>
          <div>
            International Driving Permits or other foreign driver's licenses are
            not recognized in China, so to drive a car in China, you will need
            to obtain a Chinese driver's license. Only foreigners with a valid
            residence permit can apply for a Chinese driver's license.
          </div>

          <div>
            <strong>Ride-hailing apps</strong>
          </div>
          <div>
            1. Didi (滴滴): The largest and most dominant ride-hailing platform
            in China, offering a wide range of services from private cars to
            taxis and more.
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanningPage;
