import { Link } from "react-router-dom";
import AttractionCard from "../../../../components/AttractionCard";
import bannerImg from "../../../../assets/images/lijiangbanner.jpg";
import yulongImg from "../../../../assets/images/yulongxueshan1.jpg";
import guchengImg from "../../../../assets/images/lijiang-gucheng1.jpg";
import shuheImg from "../../../../assets/images/lijiang-shuhe1.jpg";
import yuhuImg from "../../../../assets/images/lijiang-yuhu1.jpg";
import Breadcrumb from "../../../../components/Breadcrumb";
import HeroSection from "../../../../components/Hero";
import CityPageTemplate from "../../../../components/PageTemplate3";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Yunnan", path: "/province/yunnan" },
  { label: "Lijiang", path: "/city/lijiang" },
];

const RightSidebar = () => {
  return (
    <ul>
      <li>
        <a href="#about">About Lijiang</a>
      </li>
      <li>
        <a href="#attractions">Top Attractions</a>
      </li>
      <li>
        <a href="#directions">Getting There</a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Breadcrumb items={breadcrumbItems} />
      <h2 id="about" className="mt-8 text-3xl font-extrabold">
        About Lijiang
      </h2>
      <div className="my-4">
        Lijiang (丽江) is a picturesque city located in the northwest part of
        China's Yunnan Province. It is renowned for its ancient architecture,
        stunning landscapes, and rich cultural heritage.
      </div>

      <h2 id="attractions" className="mt-12 text-3xl font-extrabold">
        Top Attractions
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            name="Yulong Snow Mountain"
            desc={
              <div>
                A majestic snow mountain with cable cars.
                <br />
                Also known as Jade Dragon Snow Mountain.
              </div>
            }
            img={yulongImg}
          />
          <AttractionCard
            name="Lijiang Old Town"
            desc={
              <div>A touristy busy town located at the center of Lijiang.</div>
            }
            img={guchengImg}
          />
          <AttractionCard
            name="Shuhe Ancient Town"
            desc={
              <div>A small scenic, historic town at the north of Lijiang.</div>
            }
            img={shuheImg}
          />
          <AttractionCard
            name="Yuhu Village"
            desc={<div>A small village close to the snow mountain.</div>}
            img={yuhuImg}
          />
        </div>
      </div>

      <div id="directions">
        <h2 className="mt-12 text-3xl font-extrabold">Getting There</h2>
      </div>
      <div className="my-4 font-bold">By Air</div>
      <div>
        Lijiang Sanyi Airport (LJG): Domestic Flights: Most travelers arrive at
        Lijiang via domestic flights from major Chinese cities such as Beijing,
        Shanghai, Guangzhou, Chengdu, and Kunming. International Flights: There
        are also limited international flights from neighboring countries such
        as Thailand and Vietnam. From the Airport to Lijiang: Upon arrival at
        Lijiang Sanyi Airport, you can take a taxi or arrange for a hotel
        transfer to reach your accommodation in Lijiang Old Town or other parts
        of the city. The airport is located about 30 minutes away from Lijiang
        Old Town.
      </div>
      <div className="my-4 font-bold">By Train</div>
      <div>
        There is no high-speed train directly to Lijiang due to the mountainous
        terrain.However, you can take a high-speed train to Kunming (Kunming
        South Railway Station) first. <br />
        From Kunming to Lijiang: From Kunming, you can then take a regular train
        or a bus to Lijiang. The train journey takes around 8-10 hours, while
        buses may take longer depending on road conditions.
      </div>
      <div>Page last updated: July 29, 2024</div>
    </>
  );
};

const PageLijiang = () => {
  return (
    <CityPageTemplate
      mainContent={<MainContent />}
      hero={<HeroSection text="Lijiang" desc="" img={bannerImg} />}
      rightSidebarContent={<RightSidebar />}
    />
  );
};

export default PageLijiang;
