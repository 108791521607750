import PageTemplate3 from "../../components/PageTemplate3";

const MainContent = () => {
  return (
    <div className="mx-auto max-w-4xl p-6">
      <h1 className="mb-6 text-3xl font-bold">
        Driving in China as a Foreign Tourist
      </h1>

      <h2 className="mb-4 text-2xl font-semibold">
        1. Chinese Driving License Requirement
      </h2>
      <ul className="mb-6 ml-6 list-disc">
        <li>
          <strong>International Driving Permits (IDP):</strong> China does not
          recognize International Driving Permits or foreign driving licenses.
          To drive in China, you need to obtain a Chinese driving license.
        </li>
        <li>
          The good news is, China offers a temporary driver's license for
          foreign tourists, which is valid for the duration of their visit,
          typically up to 3 months.
        </li>
        <li>
          <strong>How to Get a Temporary Chinese Driver's License::</strong>{" "}
          Foreign tourists can apply for a temporary Chinese driving license if
          they are visiting for a short period. This is generally valid for up
          to 3 months and can be obtained in major cities, often at airports or
          local traffic management bureaus.
          <p>
            Application Process: You can apply for a temporary license at major
            cities' vehicle management offices or certain car rental companies.
          </p>
          <p>Documents Needed:</p>
          <p>Valid foreign driver's license.</p>
          <p>Passport and visa.</p>
          <p>
            A translation of your foreign driver's license into Chinese (can be
            done by a certified translator).
          </p>
          <p>Recent passport-sized photos.</p>
          <p>
            Some cities may require you to pass a simple written test on local
            driving rules and regulations.
          </p>
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">
        2. Application Process for a Temporary License
      </h2>
      <h3 className="mb-2 text-xl font-medium">Documents Needed:</h3>
      <ul className="mb-6 ml-6 list-disc">
        <li>Your original foreign driver's license.</li>
        <li>A translated and notarized copy of your driver's license.</li>
        <li>Your passport with a valid visa.</li>
        <li>A completed application form.</li>
        <li>Passport-sized photos (usually with a white background).</li>
      </ul>
      <p className="mb-4">
        <strong>Medical Examination:</strong> Some regions may require you to
        undergo a brief medical examination.
      </p>
      <p className="mb-6">
        <strong>Processing Time:</strong> The application process can typically
        be completed within a day or two, depending on the city.
      </p>

      <h2 className="mb-4 text-2xl font-semibold">
        3. Vehicle Rental and Driving
      </h2>
      <ul className="mb-6 ml-6 list-disc">
        <li>
          <strong>Car Rental:</strong> Once you have a temporary Chinese driving
          license, you can rent a car from rental agencies in major cities and
          tourist areas.
        </li>
        <li>
          <strong>Driving Conditions:</strong> China has a well-developed road
          network, but traffic can be hectic, especially in large cities. Be
          prepared for heavy traffic, different driving habits, and potential
          language barriers.
        </li>
        <li>
          <strong>Navigation:</strong> GPS navigation systems in rental cars may
          be in Chinese, so using an app like Google Maps or a translation app
          can be helpful.
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">4. Rules and Regulations</h2>
      <ul className="mb-6 ml-6 list-disc">
        <li>
          <strong>Driving on the Right:</strong> In China, vehicles drive on the
          right side of the road.
        </li>
        <li>
          <strong>Seat Belts:</strong> Seat belts are mandatory for all
          passengers.
        </li>
        <li>
          <strong>Speed Limits:</strong> Speed limits vary, but are typically
          30-50 km/h in urban areas and 100-120 km/h on highways.
        </li>
        <li>
          <strong>No Drinking and Driving:</strong> China has strict laws
          against drinking and driving, with severe penalties for violations.
        </li>
      </ul>

      <h2 className="mb-4 text-2xl font-semibold">
        5. Alternatives to Driving
      </h2>
      <ul className="mb-6 ml-6 list-disc">
        <li>
          <strong>Public Transportation:</strong> China has an extensive public
          transportation network, including high-speed trains, buses, and taxis,
          which are often more convenient and less stressful than driving.
        </li>
        <li>
          <strong>Hiring a Driver:</strong> If you prefer not to drive, you can
          hire a car with a driver. This is a popular option for tourists,
          especially in rural or less familiar areas.
        </li>
      </ul>

      <p className="mb-6">
        Driving in China as a foreign tourist is possible, but it requires some
        preparation and understanding of the local regulations. If you prefer to
        avoid the complexities, using public transportation or hiring a local
        driver are good alternatives.
      </p>
    </div>
  );
};

const PageGettingAround = () => {
  return <PageTemplate3 mainContent={<MainContent />} />;
};

export default PageGettingAround;
