import React, { useState } from "react";

import SubmenuRegions from "./SubmenuRegions";
import SubmenuTopDestinations from "./SubmenuTopDestinations";

const MenuDestinations = () => {
  const [activeSubmenu, setActiveSubmenu] = useState("topDestinations");

  const handleMouseEnter = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleMouseLeave = () => {
    // setActiveSubmenu(null);
  };

  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <SubmenuTopDestinations />
    </div>
  );
};

export default MenuDestinations;
