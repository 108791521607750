import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import bannerImg from "../../../../assets/images/shangri-la-songzanlinsi2.jpg";
import songzanlinsiImg from "../../../../assets/images/shangri-la-songzanlinsi1.jpg";
import shuheImg from "../../../../assets/images/lijiang-shuhe1.jpg";
import { useState } from "react";
import Breadcrumb from "../../../../components/Breadcrumb";
import AttractionCard from "../../../../components/AttractionCard";
import PageTemplate3 from "../../../../components/PageTemplate3";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Yunnan", path: "/province/yunnan" },
  { label: "Shangri-La", path: "/city/shangri-la" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#about");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#about"
          className={`${
            activeLink === "#about" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#about")}
        >
          About Shangri-La
        </a>
      </li>
      <li>
        <a
          href="#attractions"
          className={`${
            activeLink === "#attractions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#attractions")}
        >
          Top Attractions
        </a>
      </li>
      <li>
        <a
          href="#directions"
          className={`${
            activeLink === "#directions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#directions")}
        >
          Getting There
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>Shangri-La - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h2 id="about" className="mt-8 text-3xl font-extrabold">
        About Shangri-La
      </h2>
      <div className="my-4">
        Shangri-La (香格里拉), originally known as Zhongdian (中甸), is a city
        in the Yunnan province of China, often described as a mystical, idyllic
        paradise. Situated at an elevation of over 3,000 meters in the Tibetan
        Plateau, it is surrounded by snow-capped mountains, lush forests, and
        pristine lakes. The city is a gateway to Tibetan culture, featuring
        ancient monasteries, traditional Tibetan villages, and vibrant
        festivals. Renamed Shangri-La in 2001 to reflect the fictional paradise
        depicted in James Hilton's novel Lost Horizon, it has become a popular
        destination for those seeking adventure and a taste of Tibetan heritage.
      </div>

      <h2 id="attractions" className="mt-12 text-3xl font-extrabold">
        Top Attractions
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            name="Dukezong Ancient Town"
            desc={
              <div>
                A ancient Tibetan town, with its cobblestone streets and
                traditional wooden houses.
                <br />
                Rich in history, it's home to the world's largest prayer wheel
                and provides a glimpse into Tibetan culture and architecture.
              </div>
            }
            img={songzanlinsiImg}
          />
          <AttractionCard
            name="Songzanlin Monastery"
            desc={
              <div>
                Often referred to as the "Little Potala Palace," this Tibetan
                Buddhist monastery is the largest in Yunnan and a major
                spiritual site.
              </div>
            }
            img={songzanlinsiImg}
          />
          <AttractionCard
            name="Pudacuo National Park"
            desc={
              <div>
                A vast natural reserve encompassing beautiful landscapes,
                including Shudu Lake and Bita Lake.
                <br />
                The park is home to diverse wildlife and offers walking trails
                through pristine forests, meadows, and wetlands.
              </div>
            }
            img={shuheImg}
          />
          <AttractionCard
            name="Tiger Leaping Gorge"
            desc={
              <div>
                One of the deepest gorges in the world, located between
                Shangri-La and Lijiang.
                <br />A popular spot for hiking, offering dramatic views of the
                Jinsha River as it cuts through towering cliffs.
              </div>
            }
            img={shuheImg}
          />
          <AttractionCard
            name="Napa Lake"
            desc={
              <div>
                A seasonal highland lake surrounded by grasslands.
                <br />
                Napa Lake is especially beautiful during the wet season when it
                fills with water, attracting migratory birds like black-necked
                cranes.
              </div>
            }
            img={shuheImg}
          />
          <AttractionCard
            name="Abuji Cuo"
            desc={
              <div>
                A hiking trail leading to a serene alpine lake nestled in
                between snow-capped mountains on three sides.
              </div>
            }
            img={shuheImg}
          />
        </div>
      </div>

      <div id="directions">
        <h2 className="mt-12 text-3xl font-extrabold">Getting There</h2>
      </div>
      <div className="my-4 font-bold">By Air</div>
      <div>
        Shangri-La can be reached by air, road, or a combination of both. The
        Diqing Shangri-La Airport (DIG) offers direct flights from major Chinese
        cities like Kunming, Chengdu, and Lhasa. The airport is just a short
        drive from the city center. For those preferring overland travel, buses
        and private cars are available from Lijiang, a journey that takes around
        4-5 hours through scenic mountain roads. An alternative is to travel by
        train or bus to Lijiang from Kunming, then continue by road to
        Shangri-La. The journey offers stunning views of the Yunnan countryside,
        making it a memorable part of the adventure.
      </div>
      <div className="my-4 font-bold">Overland Travel</div>
      <div>
        For those preferring overland travel, buses and private cars are
        available from Lijiang, a journey that takes around 4-5 hours through
        scenic mountain roads. An alternative is to travel by train or bus to
        Lijiang from Kunming, then continue by road to Shangri-La. The journey
        offers stunning views of the Yunnan countryside, making it a memorable
        part of the adventure.
      </div>
      {/* <div>Page last updated: July 29, 2024</div> */}
    </>
  );
};

const PageShangriLa = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageShangriLa;
