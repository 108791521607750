import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";

import RegionsDemo from "./pages/RegionsDemo";
import AboutPage from "./pages/About";
import PageShanghai from "./pages/provinces/shanghai/index";
import PageTheBund from "./pages/provinces/shanghai/bund";
import PageLiming from "./pages/provinces/yunnan/liming/index";
import PageLijiang from "./pages/provinces/yunnan/lijiang/index";
import PageShangriLa from "./pages/provinces/yunnan/shangri-la/index";
import PageInterests from "./pages/interests";
import PageHiking from "./pages/interests/hiking";
import PageGettingAround from "./pages/planning/GettingAround";
import PlanningPage from "./pages/planning/index";
import PageMoney from "./pages/planning/Money";
import PageInternet from "./pages/planning/Internet";
import PageAccomodation from "./pages/planning/Accommodation";
import PageHome from "./pages/Homepage";
import PageLanguageBarrier from "./pages/planning/language-barrier";
import PageUnderConstruction from "./pages/UnderConstruction";
import PageWhyIDontTravelWithTourGroups from "./pages/blogs/why-i-dont-travel-with-tour-groups";
import PageTemplate4 from "./components/PageTemplate4";
import ScrollToTop from "./hooks/ScrollToTop";
import { HoverProvider } from "./hooks/HoverContext";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HoverProvider>
        <Routes>
          <Route path="/" element={<PageHome />} />
          {/* <Route path="/" element={<PageUnderConstruction />} /> */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/interests" element={<PageInterests />} />

          {/* Provinces */}
          <Route path="/provinces" element={<RegionsDemo />} />
          <Route path="/province/shanghai" element={<PageShanghai />} />
          <Route path="/province/beijing" element={<></>} />
          <Route path="/province/gansu" element={<></>} />
          <Route path="/province/qinghai" element={<></>} />
          <Route path="/province/shaanxi" element={<></>} />
          <Route path="/province/yunnan" element={<div>Yunnan</div>} />

          {/* Cities */}
          <Route path="/cities" element={<RegionsDemo />} />
          <Route path="/city/shanghai" element={<PageShanghai />} />
          <Route path="/city/lijiang" element={<PageLijiang />} />
          <Route path="/city/liming" element={<PageLiming />} />
          <Route path="/city/shangri-la" element={<PageShangriLa />} />
          {/* <Route path="/city/niseko" element={<NisekoPage />} /> */}

          {/* Attractions */}
          <Route path="/attraction/thebund" element={<PageTheBund />} />

          {/* Planning */}
          <Route path="/planning" element={<PlanningPage />} />
          <Route path="/planning/visa" element={<></>} />
          <Route path="/planning/money" element={<PageMoney />} />
          <Route path="/planning/internet" element={<PageInternet />} />
          <Route path="/planning/maps" element={<></>} />
          <Route
            path="/planning/getting-around"
            element={<PageGettingAround />}
          />
          <Route
            path="/planning/accommodation"
            element={<PageAccomodation />}
          />
          <Route
            path="/planning/language-barrier"
            element={<PageLanguageBarrier />}
          />
          <Route path="/planning/when-to-go" element={<></>} />

          {/* Blogs */}
          <Route
            path="/blogs/why-i-dont-travel-with-tour-groups"
            element={<PageWhyIDontTravelWithTourGroups />}
          />

          {/* sandbox */}
          <Route path="/map" element={<PageTemplate4 />} />
        </Routes>
      </HoverProvider>
    </BrowserRouter>
  );
}

export default App;
