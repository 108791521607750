import Header from "./Header";

const HeroSection = ({ text, desc, img }) => (
  <div
    className="relative h-96 bg-cover bg-center bg-no-repeat"
    style={{
      backgroundImage: `url(${img})`,
      overflow: "hidden",
      height: "43rem",
    }}
  >
    <Header />
    <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
      <h1 className="mb-4 text-6xl font-bold">{text}</h1>
      <a
        href="#"
        className="rounded-md bg-white px-6 py-3 font-semibold text-black transition duration-300 hover:bg-gray-100"
      >
        Find your destination
      </a>
    </div>
    <div className="absolute bottom-4 right-4 text-sm text-white">
      Dahaidao, Xinjiang
    </div>
  </div>
);

export default HeroSection;
