import React from "react";
import { Star, Search, User } from "lucide-react";

const CityHeader = ({ province, city, description, tags }) => {
  return (
    <div className="mx-auto max-w-6xl">
      <div className="mt-8">
        <p className="text-sm">{province}</p>
        <h1 className="mt-2 text-3xl font-bold">{city}</h1>
        <p className="text-gray-600">{description}</p>
        <div className="mt-2 flex items-center">
          {tags}
          <div className="flex items-center">
            <Star className="h-4 w-4 fill-current text-yellow-400" />
            <Star className="h-4 w-4 fill-current text-yellow-400" />
            <Star className="h-4 w-4 fill-current text-yellow-400" />
            <Star className="h-4 w-4 fill-current text-yellow-400" />
            <Star className="h-4 w-4 fill-current text-gray-300" />
            {/* <span className="ml-1 text-sm">4.1 (185)</span> */}
          </div>
          <span className="ml-4 text-sm text-gray-600">
            #92 of 208 most visited in Japan
          </span>
        </div>
      </div>
    </div>
  );
};

export default CityHeader;
