import React, { useState } from "react";
import { Link } from "react-router-dom";

const MenuPlanning = () => {
  return (
    <div className="rounded-lg bg-white p-6 shadow-md">
      <div className="mb-4 flex items-center justify-between">
        <div id="nav-items" className="flex space-x-4">
          <span className="cursor-pointer">
            <Link to="/planning/visa">Visa</Link>
          </span>
          <span className="cursor-pointer">
            <Link to="/planning/money">Money</Link>
          </span>
          <span className="cursor-pointer">
            <Link to="/planning/internet">Internet</Link>
          </span>
          <span className="cursor-pointer text-black">
            <Link to="/planning/maps">Maps</Link>
          </span>
          <span className="cursor-pointer text-black">
            <Link to="/planning/getting-around">Getting Around</Link>
          </span>
          <span className="cursor-pointer text-black">
            <Link to="/planning/accommodation">Accommodation</Link>
          </span>
          <span className="cursor-pointer text-black">
            <Link to="/planning/language-barrier">Language Barrier</Link>
          </span>
          <span className="cursor-pointer text-black">
            <Link to="/planning/when-to-go">When to go</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default MenuPlanning;
