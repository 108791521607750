import React, { useState, useRef, useEffect } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  Marker,
  Popup,
  useMap,
  useMapEvents,
} from "react-leaflet";

import AttractionCard from "../components/AttractionCard";
import { geoJson } from "../data/provinces_geojson";
// import { geoJson } from "../data/provinces_cities";
import songzanlinsiImg from "../assets/images/shangri-la-songzanlinsi1.jpg";
import "leaflet/dist/leaflet.css";
import "../PageTemplate4.css";
import { Link } from "react-router-dom";
import MapResetViewControl from "./MapResetViewControl";
import LandingPage from "./LandingPage";

const attractionsGeojsons = {
  xinjiang: "../data/geojson_xinjiang_attractions.json",
  sichuan: "../data/geojson_sichuan_attractions.json",
  //   Sichuan: "../data/attractions_sichuan.json",
  //     paths for other provinces...
};

const ZoomHandler = ({ onZoomEnd }) => {
  const map = useMapEvents({
    zoomend: () => {
      const zoom = map.getZoom();
      onZoomEnd(zoom);
    },
  });
  return null;
};

const PageTemplate4 = () => {
  const provinceStyle = {
    fillColor: "#000",
    fillOpacity: 0.05,
    // border: "1px solid green",
    weight: 1,
    opacity: 0.5,
    color: "white",
  };
  const hoverStyle = {
    fillColor: "green",
    fillOpacity: 0.05,
    weight: 1,
    color: "#333",
  };

  // State to manage active menu, hovered provinces, and expanded header
  const [activeMainMenu, setActiveMainMenu] = useState("Home");
  const [activeSecondaryMenu, setActiveSecondaryMenu] = useState("Map");
  const [activeContent, setActiveContent] = useState("");
  const [activeProvince, setActiveProvince] = useState();
  const [hoveredProvince, setHoveredProvince] = useState(null);
  const [provinceAttractions, setProvinceAttractions] = useState(null);
  const [activeFilter, setActiveFilter] = useState("all");
  const [filteredAttractions, setFilteredAttractions] =
    useState(provinceAttractions);
  const [showProvincesMenu, setShowProvincesMenu] = useState(false);

  // Reference to the map for zoom control
  const mapRef = useRef();
  const initialCenter = [38, 104.1954];
  const initialZoom = 5;
  const [zoomLevel, setZoomLevel] = useState(initialZoom);

  const filterItems = (type) => {
    if (type === "all") {
      setFilteredAttractions(provinceAttractions); // Reset to all items
    } else {
      console.log("provinceAttractions", provinceAttractions);
      const filtered = provinceAttractions.features.filter(
        (item) => item.properties.type === type,
      );
      console.log("filtered", filtered);
      setFilteredAttractions({
        type: "FeatureCollection",
        features: filtered,
      });
    }
    setActiveFilter(type); // Set the active filter
  };

  // Event: Highlight province on the map when hovered over
  const handleMouseOver = (event) => {
    const layer = event.target;
    // layer.setStyle({
    //   color: "green",
    //   fillColor: "red", // Light blue for hover effect
    //   weight: 1,
    //   fillOpacity: 1,
    // });
    setHoveredProvince(layer.feature.properties.name);
  };

  const handleMouseOut = (event) => {
    const layer = event.target;
    layer.setStyle(provinceStyle);
    setHoveredProvince(null);
  };

  // Handle province click on both map and menu
  const handleProvinceClick = async (province) => {
    const provinceName = province.properties.name;
    const provinceCenter = province.properties.center;
    const provinceZoom = province.properties.zoom;

    if (mapRef.current && provinceCenter) {
      mapRef.current.setView(
        [provinceCenter[1], provinceCenter[0]],
        provinceZoom || 7,
      );
      setActiveProvince(provinceName);
    }
    console.log("fetching", attractionsGeojsons[provinceName.toLowerCase()]);
    try {
      setProvinceAttractions();
      // Dynamically import the JSON file based on the province name
      const attractionJson = await import(
        `../data/geojson_${provinceName.toLowerCase()}_attractions.json`
      );
      setProvinceAttractions(attractionJson.default); // Set the data to be used by GeoJSON
    } catch (error) {
      console.error(`Error loading JSON for ${provinceName}:`, error);
    }
  };

  // Callback function to handle zoom changes
  const handleZoomEnd = () => {
    const map = mapRef.current;
    if (map != null) {
      console.log("zoom ended", map.getZoom());
      setZoomLevel(map.getZoom());
    }
  };

  // Function to handle province highlighting from the menu
  const handleProvinceHoverFromMenu = (provinceName) => {
    setHoveredProvince(provinceName);
  };

  const onEachProvince = (province, layer) => {
    layer.on({
      mouseover: handleMouseOver,
      mouseout: handleMouseOut,
      click: () => handleProvinceClick(province),
    });
  };

  useEffect(() => {
    setFilteredAttractions(provinceAttractions);
  }, [provinceAttractions]);

  return (
    <div className="app-container">
      {/* Top Horizontal Menu */}
      <header className="main-menu p-4">
        <nav>
          <ul className="flex list-none justify-around">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li
              onClick={() => {
                setActiveMainMenu("Destinations");
                setShowProvincesMenu(!showProvincesMenu); // Toggle provinces list
              }}
            >
              Destinations
            </li>
            <li onClick={() => setActiveMainMenu("Routes")}>Routes</li>
          </ul>
          {showProvincesMenu && (
            <ul className="provinces-menu flex list-none justify-around p-4">
              {geoJson.features.map((province) => (
                <li
                  key={province.properties.name}
                  className="cursor-pointer px-4 py-2 hover:bg-gray-600"
                  onMouseEnter={() =>
                    handleProvinceHoverFromMenu(province.properties.name)
                  }
                  onMouseLeave={() => setHoveredProvince(null)}
                  onClick={() => handleProvinceClick(province)}
                >
                  {province.properties.name}
                </li>
              ))}
            </ul>
          )}
        </nav>
      </header>

      <div className="content-container">
        {/* Left Secondary Menu */}
        <aside className="secondary-menu h-screen w-2/5 min-w-[300px] overflow-y-auto bg-gray-200 p-4">
          <LandingPage
            mapRef={mapRef}
            handleProvinceClick={handleProvinceClick}
            handleProvinceHoverFromMenu={handleProvinceHoverFromMenu}
          />
          <ul>
            <li onClick={() => setActiveSecondaryMenu("Settings")}>Settings</li>
          </ul>
          <div className="mb-4 flex space-x-4">
            <button
              className={`px-4 py-2 ${
                activeFilter === "All"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => filterItems("all")}
            >
              All
            </button>
            <button
              className={`px-4 py-2 ${
                activeFilter === "Nature"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => filterItems("Nature")}
            >
              Nature
            </button>
            <button
              className={`px-4 py-2 ${
                activeFilter === "Historical Site"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => filterItems("Historical Site")}
            >
              Historical Sites
            </button>
            <button
              className={`px-4 py-2 ${
                activeFilter === "Urban City"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200"
              }`}
              onClick={() => filterItems("Urban City")}
            >
              Urban Cities
            </button>
          </div>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {filteredAttractions &&
              filteredAttractions.features.map((attraction) => {
                return (
                  <div
                    onClick={() => {
                      console.log("clicked!");
                      setActiveContent(attraction.properties.description);
                    }}
                  >
                    <AttractionCard
                      name={attraction.properties.name}
                      desc={attraction.properties.description}
                      img={songzanlinsiImg}
                    />
                  </div>
                );
              })}
          </div>
        </aside>

        {/* Main Content: Leaflet Map */}
        <main className="flex flex-1 items-center">
          <MapContainer
            center={initialCenter}
            zoom={initialZoom}
            style={{ height: "100%", width: "100%" }}
            ref={(mapInstance) => {
              mapRef.current = mapInstance; // Set the map reference
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* country-level geojson */}
            <GeoJSON
              data={geoJson}
              style={(feature) =>
                feature.properties.name === hoveredProvince
                  ? hoverStyle // Hover style
                  : provinceStyle
              }
              onEachFeature={onEachProvince}
            />
            {filteredAttractions && (
              <GeoJSON
                key={activeProvince}
                data={filteredAttractions}
                style={{ color: "green" }}
                onEachFeature={(feature, layer) => {
                  const { name, chineseName, description } = feature.properties;
                  layer.bindPopup(
                    `<strong>${name} (${chineseName})</strong><br>${description}`,
                  );
                }}
              />
            )}
            <MapResetViewControl
              initialCenter={initialCenter}
              initialZoom={initialZoom}
              callback={() => {
                setActiveProvince();
                setProvinceAttractions();
              }}
            />
            <ZoomHandler onZoomEnd={handleZoomEnd} />
          </MapContainer>
          {activeContent && <div className="flex w-full">{activeContent}</div>}
          {activeSecondaryMenu === "Settings" && (
            <div>Settings content goes here.</div>
          )}
        </main>
      </div>
    </div>
  );
};

export default PageTemplate4;
