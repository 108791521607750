import React from "react";

const SubmenuCities = () => {
  const provinces = [
    {
      name: "Beijing",
      chineseName: "北京",
      cities: ["Beijing (北京)"],
      color: "border-red-300",
    },
    {
      name: "Tianjin",
      chineseName: "天津",
      cities: ["Tianjin (天津)"],
      color: "border-red-300",
    },
    {
      name: "Hebei",
      chineseName: "河北",
      cities: [
        "Shijiazhuang (石家庄)",
        "Tangshan (唐山)",
        "Baoding (保定)",
        "Handan (邯郸)",
        "Xingtai (邢台)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Shanxi",
      chineseName: "山西",
      cities: [
        "Taiyuan (太原)",
        "Datong (大同)",
        "Changzhi (长治)",
        "Jincheng (晋城)",
        "Yangquan (阳泉)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Inner Mongolia",
      chineseName: "内蒙古",
      cities: [
        "Hohhot (呼和浩特)",
        "Baotou (包头)",
        "Ordos (鄂尔多斯)",
        "Chifeng (赤峰)",
        "Tongliao (通辽)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Liaoning",
      chineseName: "辽宁",
      cities: [
        "Shenyang (沈阳)",
        "Dalian (大连)",
        "Anshan (鞍山)",
        "Fushun (抚顺)",
        "Benxi (本溪)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Jilin",
      chineseName: "吉林",
      cities: [
        "Changchun (长春)",
        "Jilin (吉林)",
        "Siping (四平)",
        "Liaoyuan (辽源)",
        "Tonghua (通化)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Heilongjiang",
      chineseName: "黑龙江",
      cities: [
        "Harbin (哈尔滨)",
        "Qiqihar (齐齐哈尔)",
        "Jixi (鸡西)",
        "Daqing (大庆)",
        "Mudanjiang (牡丹江)",
      ],
      color: "border-orange-300",
    },
    {
      name: "Shanghai",
      chineseName: "上海",
      cities: ["Shanghai (上海)"],
      color: "border-red-300",
    },
    {
      name: "Jiangsu",
      chineseName: "江苏",
      cities: [
        "Nanjing (南京)",
        "Suzhou (苏州)",
        "Wuxi (无锡)",
        "Changzhou (常州)",
        "Nantong (南通)",
      ],
      color: "border-red-300",
    },
    {
      name: "Zhejiang",
      chineseName: "浙江",
      cities: [
        "Hangzhou (杭州)",
        "Ningbo (宁波)",
        "Wenzhou (温州)",
        "Jiaxing (嘉兴)",
        "Huzhou (湖州)",
      ],
      color: "border-red-300",
    },
    {
      name: "Anhui",
      chineseName: "安徽",
      cities: [
        "Hefei (合肥)",
        "Wuhu (芜湖)",
        "Bengbu (蚌埠)",
        "Huainan (淮南)",
        "Ma'anshan (马鞍山)",
      ],
      color: "border-green-300",
    },
    {
      name: "Fujian",
      chineseName: "福建",
      cities: [
        "Fuzhou (福州)",
        "Xiamen (厦门)",
        "Quanzhou (泉州)",
        "Putian (莆田)",
        "Zhangzhou (漳州)",
      ],
      color: "border-green-300",
    },
    {
      name: "Jiangxi",
      chineseName: "江西",
      cities: [
        "Nanchang (南昌)",
        "Jingdezhen (景德镇)",
        "Pingxiang (萍乡)",
        "Jiujiang (九江)",
        "Xinyu (新余)",
      ],
      color: "border-green-300",
    },
    {
      name: "Shandong",
      chineseName: "山东",
      cities: [
        "Jinan (济南)",
        "Qingdao (青岛)",
        "Zibo (淄博)",
        "Yantai (烟台)",
        "Weifang (潍坊)",
      ],
      color: "border-green-300",
    },
    {
      name: "Henan",
      chineseName: "河南",
      cities: [
        "Zhengzhou (郑州)",
        "Luoyang (洛阳)",
        "Kaifeng (开封)",
        "Anyang (安阳)",
        "Xinxiang (新乡)",
      ],
      color: "border-green-300",
    },
    {
      name: "Hubei",
      chineseName: "湖北",
      cities: [
        "Wuhan (武汉)",
        "Huangshi (黄石)",
        "Shiyan (十堰)",
        "Yichang (宜昌)",
        "Xiangyang (襄阳)",
      ],
      color: "border-blue-300",
    },
    {
      name: "Hunan",
      chineseName: "湖南",
      cities: [
        "Changsha (长沙)",
        "Zhuzhou (株洲)",
        "Xiangtan (湘潭)",
        "Hengyang (衡阳)",
        "Shaoyang (邵阳)",
      ],
      color: "border-blue-300",
    },
    {
      name: "Guangdong",
      chineseName: "广东",
      cities: [
        "Guangzhou (广州)",
        "Shenzhen (深圳)",
        "Dongguan (东莞)",
        "Foshan (佛山)",
        "Zhuhai (珠海)",
      ],
      color: "border-blue-300",
    },
    {
      name: "Guangxi",
      chineseName: "广西",
      cities: [
        "Nanning (南宁)",
        "Guilin (桂林)",
        "Liuzhou (柳州)",
        "Wuzhou (梧州)",
        "Beihai (北海)",
      ],
      color: "border-blue-300",
    },
    {
      name: "Hainan",
      chineseName: "海南",
      cities: [
        "Haikou (海口)",
        "Sanya (三亚)",
        "Sansha (三沙)",
        "Wenchang (文昌)",
        "Qionghai (琼海)",
      ],
      color: "border-blue-300",
    },
    {
      name: "Chongqing",
      chineseName: "重庆",
      cities: ["Chongqing (重庆)"],
      color: "border-teal-300",
    },
    {
      name: "Sichuan",
      chineseName: "四川",
      cities: [
        "Chengdu (成都)",
        "Mianyang (绵阳)",
        "Leshan (乐山)",
        "Nanchong (南充)",
        "Deyang (德阳)",
      ],
      color: "border-teal-300",
    },
    {
      name: "Guizhou",
      chineseName: "贵州",
      cities: [
        "Guiyang (贵阳)",
        "Zunyi (遵义)",
        "Liupanshui (六盘水)",
        "Anshun (安顺)",
        "Kaili (凯里)",
      ],
      color: "border-teal-300",
    },
    {
      name: "Yunnan",
      chineseName: "云南",
      cities: [
        "Kunming (昆明)",
        "Qujing (曲靖)",
        "Yuxi (玉溪)",
        "Baoshan (保山)",
        "Zhaotong (昭通)",
      ],
      color: "border-teal-300",
    },
    {
      name: "Tibet",
      chineseName: "西藏",
      cities: [
        "Lhasa (拉萨)",
        "Shigatse (日喀则)",
        "Qamdo (昌都)",
        "Nyingchi (林芝)",
        "Nagqu (那曲)",
      ],
      color: "border-teal-300",
    },
    {
      name: "Shaanxi",
      chineseName: "陕西",
      cities: [
        "Xi'an (西安)",
        "Xianyang (咸阳)",
        "Tongchuan (铜川)",
        "Baoji (宝鸡)",
        "Weinan (渭南)",
      ],
      color: "border-purple-300",
    },
    {
      name: "Gansu",
      chineseName: "甘肃",
      cities: [
        "Lanzhou (兰州)",
        "Jiayuguan (嘉峪关)",
        "Tianshui (天水)",
        "Baiyin (白银)",
        "Wuwei (武威)",
      ],
      color: "border-purple-300",
    },
    {
      name: "Qinghai",
      chineseName: "青海",
      cities: [
        "Xining (西宁)",
        "Haidong (海东)",
        "Haibei (海北)",
        "Huangnan (黄南)",
        "Yushu (玉树)",
      ],
      color: "border-purple-300",
    },
    {
      name: "Ningxia",
      chineseName: "宁夏",
      cities: [
        "Yinchuan (银川)",
        "Shizuishan (石嘴山)",
        "Wuzhong (吴忠)",
        "Guyuan (固原)",
        "Zhongwei (中卫)",
      ],
      color: "border-purple-300",
    },
    {
      name: "Xinjiang",
      chineseName: "新疆",
      cities: [
        "Urumqi (乌鲁木齐)",
        "Kashgar (喀什)",
        "Hotan (和田)",
        "Aksu (阿克苏)",
        "Karamay (克拉玛依)",
      ],
      color: "border-purple-300",
    },
  ];

  return (
    <div className="grid grid-cols-6 gap-6">
      {provinces.map((province, index) => (
        <div key={index} className={`border-l-4 ${province.color} pl-3`}>
          <h3 className="mb-2 font-bold">{province.name}</h3>
          <ul>
            {province.cities.map((city, cityIndex) => (
              <li key={cityIndex} className="mb-1 font-thin text-gray-600">
                {city}
              </li>
            ))}
          </ul>
        </div>
      ))}
      <div className="col-span-2">
        <img
          src="/path/to/china-map.png"
          alt="Map of China"
          className="h-auto w-full"
        />
      </div>
    </div>
  );
};

export default SubmenuCities;
