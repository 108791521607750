import { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const MapResetViewControl = ({ initialCenter, initialZoom, callback }) => {
  const map = useMap(); // Get the map instance

  useEffect(() => {
    // Define the custom reset view control
    const resetControl = L.Control.extend({
      options: {
        position: "topleft",
      },
      onAdd: function () {
        const button = L.DomUtil.create(
          "button",
          "leaflet-bar leaflet-control leaflet-control-custom",
        );
        button.innerHTML = "Reset Map";
        button.style.backgroundColor = "#fff";
        button.style.padding = "5px";

        button.onclick = function () {
          map.setView(initialCenter, initialZoom); // Reset the view to initial center and zoom
          if (callback) {
            callback();
          }
        };

        return button;
      },
    });

    // Add the custom control to the map
    const control = new resetControl();
    map.addControl(control);

    // Clean up control when component unmounts
    return () => {
      map.removeControl(control);
    };
  }, [map, initialCenter, initialZoom]);

  return null; // No visible UI component here
};

export default MapResetViewControl;
