import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
// import bannerImg from "../assets/images/shangri-la-songzanlinsi2.jpg";
import bannerImg from "../assets/images/beijing_jiaolou_banner.jpg";
import { useState } from "react";
import PageTemplate3 from "../components/PageTemplate3";
import AttractionCard from "../components/AttractionCard";
import beijingImg from "../assets/images/tiananmen1.jpg";
import chengduImg from "../assets/images/chengdu2.jpg";
import chongqingImg from "../assets/images/chongqing1.jpg";
import hongkongImg from "../assets/images/hongkong1.jpg";
import shanghaiImg from "../assets/images/shanghai2.jpg";
import xianImg from "../assets/images/xian1.jpg";
import natureImg from "../assets/images/hiking2.jpg";
import historicalImg from "../assets/images/mogaoku2.jpg";
import urbanImg from "../assets/images/hongkong2.jpg";
import ChinaMap from "../components/MapProvinces/ChinaMap";
import PageUnderConstruction from "./UnderConstruction";

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#about");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#top-destinations"
          className={`${
            activeLink === "#top-destinations" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#top-destinations")}
        >
          Top Destinations
        </a>
      </li>
      <li>
        <a
          href="#interests"
          className={`${
            activeLink === "#interests" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#interests")}
        >
          Interests
        </a>
      </li>
      <li>
        <a
          href="#directions"
          className={`${
            activeLink === "#directions" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#directions")}
        >
          Getting There
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>China Travel Guide - superchinaguide.com</title>
      </Helmet>

      {/* <div id="region">
        <h2 className="mt-16 text-3xl font-extrabold">Regions</h2>

        <div className="my-4">
          <ChinaMap />
        </div>
      </div> */}
      <h2 id="top-destinations" className="mt-8 text-3xl font-extrabold">
        Top Destinations
      </h2>
      <div className="my-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
          <AttractionCard
            link="/city/shanghai"
            name="Shanghai"
            desc={
              <div>
                From hip shopping to world-class dining, this international
                metropolis' got it all.
              </div>
            }
            img={shanghaiImg}
          />
          <AttractionCard
            link="/city/beijing"
            name="Beijing"
            desc={
              <div>
                China's political center. Home to the Great Wall and the
                Forbidden City.
              </div>
            }
            img={beijingImg}
          />
          <AttractionCard
            name="Hong Kong"
            desc={
              <div>
                A special administrative region known for its unique blend of
                Chinese and Western influcences.
              </div>
            }
            img={hongkongImg}
          />
          <AttractionCard
            name="Xi'an"
            desc={
              <div>
                Once known as Chang’an (Eternal Peace), it is the former capital
                of the Zhou, Qin, Han and Tang dynasties. Home of the terracotta
                warriors.
              </div>
            }
            img={xianImg}
          />
          <AttractionCard
            name="Chengdu"
            desc={
              <div>
                Capital of Sichuan province and home of the giant pandas!
              </div>
            }
            img={chengduImg}
          />
          <AttractionCard
            name="Chongqing"
            desc={
              <div>
                A large city known for its mountain-river scenary and abundant
                cultural sites.
              </div>
            }
            img={chongqingImg}
          />
        </div>
      </div>

      <div id="interests">
        <h2 className="mt-16 text-3xl font-extrabold">Interests</h2>

        <div className="my-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            <AttractionCard
              link="/city/shanghai"
              name="Nature"
              desc={
                <div>
                  This may come as a surprise, but China's got some of the
                  world's best outdoor spots.
                </div>
              }
              img={natureImg}
            />
            <AttractionCard
              link="/attraction/nanjingroad"
              name="Historical Sites"
              desc={<div>Discover 5000 years of history.</div>}
              img={historicalImg}
            />
            <AttractionCard
              name="Urban Cities"
              desc={
                <div>
                  Skyscrapers, Michelin Star restaurants, trendy shopping 'n
                  more.
                </div>
              }
              img={urbanImg}
            />
          </div>
        </div>
      </div>

      <div id="blogs">
        <h2 className="mt-16 text-3xl font-extrabold">Blogs</h2>
        <div className="my-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3">
            <AttractionCard
              name="Why I don't travel with tour groups"
              img={natureImg}
              link="/blogs/why-i-dont-travel-with-tour-groups"
            />
          </div>
        </div>
      </div>
    </>
  );
};

const PageHome = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageHome;
