import PageTemplate3 from "../../../../components/PageTemplate3";
import bannerImg from "../../../../assets/images/liming-banner.jpg";

const PageLiming = () => {
  return <PageTemplate3 bannerImg={bannerImg} content={<Content />} />;
};

const Content = () => {
  return (
    <div>
      <div className="mt-4 flex border-b">
        <button className="border-b-2 border-red-500 px-4 py-2 font-medium">
          Intro
        </button>
        <button className="px-4 py-2 font-medium text-gray-500">
          Get There
        </button>
        <button className="px-4 py-2 font-medium text-gray-500">
          Get Around
        </button>
      </div>

      {/* Main Content and Booking Section */}
      <div className="mt-8 flex flex-col md:flex-row">
        <div className="pr-8 md:w-2/3">
          <p className="mb-4">
            This is a better version because viewers can see the content without
            scrolling.
            <br /> Niseko (ニセコ) is the most famous ski resort in Japan, known
            for having tons of light powder snow, spectacular backcountry and a
            large number of foreigners - especially Australians - who in recent
            decades have been responsible for popularizing the resort area with
            the skiing and snowboarding community outside of Japan. As a result,
            Niseko's resorts are very accessible and welcoming to foreign
            visitors, which they keep busy with plenty of vast, long ski runs,
            endless powder, and a growing number of after-ski activities.
          </p>
          <p>
            In addition to kilometers of ski trails, many of Niseko's resorts
            offer winter adventure seekers the option to explore off trail
            skiing, a relatively uncommon attraction at most other ski resorts
            in Japan. The backcountry can be accessed through special gates
            around the resorts or experienced on guided tours and helicopter
            tours. The latter is also possible on nearby Mount Yotei, a nearly
            perfect volcanic cone, which can be seen from Niseko.
          </p>
        </div>
        <div className="mt-8 md:mt-0 md:w-1/3">
          <div className="rounded-lg bg-gray-100 p-4">
            <h2 className="mb-4 text-xl font-bold">Book your trip</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  City:
                </label>
                <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                  <option>Niseko</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Check-in:
                </label>
                <input
                  type="date"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Nights:
                </label>
                <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm">
                  <option>1 night</option>
                </select>
              </div>
              <button className="w-full rounded-md bg-red-500 py-2 text-white hover:bg-red-600">
                Search hotels
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLiming;
