import React, { useRef } from "react";
import { geoJson } from "../data/provinces_geojson";
import beijingImg from "../assets/images/tiananmen1.jpg";

const LandingPage = ({
  mapRef,
  handleProvinceClick,
  handleProvinceHoverFromMenu,
}) => {
  // Reference to the map for zoom control
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Plan Your Trip Section */}
      <section className="bg-white px-4 py-4">
        <h2 className="mb-8 text-center text-3xl font-semibold">
          Plan Your Trip
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {/* Card 1: Before you go */}
          <div className="rounded-lg bg-gray-200 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Before you go</h3>
          </div>

          {/* Card 2: Itinerary Ideas */}
          <div className="rounded-lg bg-gray-200 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Itinerary Ideas</h3>
          </div>

          {/* Card 3: Transportation in China */}
          <div className="rounded-lg bg-gray-200 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Transportation</h3>
          </div>
        </div>
      </section>

      {/* Find Your Interest Section */}
      <section className="bg-gray-50 px-4 py-4">
        <h2 className="mb-8 text-center text-3xl font-semibold">
          Find Your Interest
        </h2>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
          {/* Card 1: Nature */}
          <div className="rounded-lg bg-green-100 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Nature</h3>
          </div>

          {/* Card 2: History / Culture */}
          <div className="rounded-lg bg-yellow-100 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">History / Culture</h3>
          </div>

          {/* Card 3: Cities */}
          <div className="rounded-lg bg-blue-100 p-6 shadow-lg">
            <h3 className="mb-4 text-xl font-semibold">Cities</h3>
          </div>
        </div>
      </section>

      <section className="bg-gray-50 px-4 py-4">
        <h2 className="mb-8 text-center text-3xl font-semibold">By Province</h2>
        <ProvinceCards
          mapRef={mapRef}
          handleProvinceClick={handleProvinceClick}
          handleProvinceHoverFromMenu={handleProvinceHoverFromMenu}
        />
      </section>
    </div>
  );
};

const ProvinceCards = ({
  mapRef,
  handleProvinceClick,
  handleProvinceHoverFromMenu,
}) => {
  const getImagePath = (provinceName) => {
    try {
      return require(
        `../assets/images/provinces/${provinceName.toLowerCase()}.jpg`,
      );
    } catch (error) {
      // Fallback image if the province image doesn't exist
      return require("../assets/images/provinces/shanghai.jpg");
    }
  };
  return (
    <div className="grid grid-cols-2 gap-4 p-4 md:grid-cols-3 lg:grid-cols-4">
      {geoJson.features.map((province) => {
        return (
          <div
            key={province.properties.name}
            className="relative cursor-pointer overflow-hidden rounded hover:shadow-lg"
            onMouseEnter={() => {
              if (mapRef.current && province.properties.center) {
                mapRef.current.setView(
                  [
                    province.properties.center[1],
                    province.properties.center[0],
                  ],
                  5,
                );
              }
              handleProvinceHoverFromMenu(province.properties.name);
            }}
            onMouseLeave={() => {
              // Optionally, reset zoom or set to a default zoom when hover leaves
              handleProvinceHoverFromMenu(null);
            }}
            onClick={() => handleProvinceClick(province)}
          >
            {/* Placeholder Image - Replace with actual image if available */}
            <img
              src={getImagePath(province.properties.name)} // Replace with actual image paths
              alt={province.properties.name}
              className="h-32 w-full object-cover"
            />
            <div className="absolute bottom-0 left-0 w-full bg-gray-900 bg-opacity-75 py-2 text-center text-white">
              {province.properties.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LandingPage;
