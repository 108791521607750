import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import iconAlipay from "../../assets/images/icon_alipay.png";
import iconWechat from "../../assets/images/icon_wechat.png";
import bannerImg from "../../assets/images/shangri-la-songzanlinsi2.jpg";
import Breadcrumb from "../../components/Breadcrumb";
import PageTemplate3 from "../../components/PageTemplate3";
import { useState } from "react";

const breadcrumbItems = [
  { label: "Home", path: "/" },
  { label: "Planning", path: "/planning" },
  { label: "Money", path: "/planning/money" },
];

const RightSidebarContent = () => {
  const [activeLink, setActiveLink] = useState("#overview");

  return (
    <ul className="space-y-2 text-sm">
      <li>
        <a
          href="#overview"
          className={`${
            activeLink === "#overview" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#overview")}
        >
          Overview
        </a>
      </li>
      <li>
        <a
          href="#alipay"
          className={`${
            activeLink === "#alipay" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#alipay")}
        >
          Alipay
        </a>
      </li>
      <li>
        <a
          href="#wechat"
          className={`${
            activeLink === "#wechat" ? "no-underline" : "underline"
          }`}
          onClick={() => setActiveLink("#wechat")}
        >
          Wechat
        </a>
      </li>
      <li className="mb-4">
        <a
          href="#cash"
          className={`${activeLink === "#cash" ? "no-underline" : "underline"}`}
          onClick={() => setActiveLink("#cash")}
        >
          Cash
        </a>
      </li>
    </ul>
  );
};

const MainContent = () => {
  return (
    <>
      <Helmet>
        <title>Internet in China - superchinaguide.com</title>
      </Helmet>
      <Breadcrumb items={breadcrumbItems} />
      <h1 className="mt-8 text-4xl font-extrabold">Internet in China</h1>
      <h2 id="overview" className="mt-8 text-3xl font-extrabold">
        Overview
      </h2>
      <div className="mx-auto max-w-2xl rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-6 text-3xl font-semibold">
          How to Get a SIM Card as a Foreigner in China
        </h2>

        <ol className="list-inside list-decimal space-y-4 text-lg">
          <li>
            <span className="font-medium">Choose a Mobile Carrier:</span> China
            has three main carriers:
            <ul className="ml-6 list-inside list-disc">
              <li>
                <strong>China Mobile:</strong> The largest provider with
                extensive coverage, especially in rural areas.
              </li>
              <li>
                <strong>China Unicom:</strong> Popular among foreigners due to
                better international services.
              </li>
              <li>
                <strong>China Telecom:</strong> Also has good coverage and
                competitive rates.
              </li>
            </ul>
          </li>
          <li>
            <span className="font-medium">Visit an Official Store:</span> Visit
            an official store of the chosen carrier, found in airports, malls,
            or city centers.
          </li>
          <li>
            <span className="font-medium">Bring Necessary Documents:</span>{" "}
            Bring your <strong>passport</strong> and possibly your{" "}
            <strong>visa or entry permit</strong> for identity verification.
          </li>
          <li>
            <span className="font-medium">Choose Your Plan:</span> Choose a plan
            based on your data, call, and SMS needs. Staff will help you find a
            suitable option.
          </li>
          <li>
            <span className="font-medium">Complete Registration:</span> The
            staff will register your SIM card using your passport and other
            required information.
          </li>
          <li>
            <span className="font-medium">
              Insert and Activate the SIM Card:
            </span>{" "}
            The SIM card will be activated by the staff, allowing you to start
            using it immediately.
          </li>
          <li>
            <span className="font-medium">
              Top-Up Your Balance (If Prepaid):
            </span>{" "}
            If you chose a prepaid plan, top up your balance either at the store
            or via online methods like WeChat or Alipay.
          </li>
          <li>
            <span className="font-medium">
              Consider VPN for Internet Access:
            </span>{" "}
            Set up a VPN before arriving in China to access websites and
            services that might be blocked.
          </li>
          <li>
            <span className="font-medium">Manage Your Plan:</span> Use the
            carrier’s app, online services, or customer service to manage your
            plan, check balances, or top up.
          </li>
          <li>
            <span className="font-medium">Keep Your SIM Card Active:</span> If
            you leave China temporarily, keep a minimal balance or use the SIM
            occasionally to prevent it from expiring.
          </li>
        </ol>
      </div>
    </>
  );
};

const PageInternet = () => {
  return (
    <PageTemplate3
      mainContent={<MainContent />}
      bannerImg={bannerImg}
      rightSidebarContent={<RightSidebarContent />}
    />
  );
};

export default PageInternet;
