import React, { useRef, useEffect, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";

function ShanghaiMap() {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng] = useState(121.4737);
  const [lat] = useState(31.2304);
  const [zoom] = useState(10);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new maplibregl.Map({
      container: mapContainer.current,
      style: "https://demotiles.maplibre.org/style.json", // You may want to use a different style
      center: [lng, lat],
      zoom: zoom,
    });
  }, [lng, lat, zoom]);

  return (
    <div className="map-wrap">
      <div ref={mapContainer} className="map" />
    </div>
  );
}

export default ShanghaiMap;
