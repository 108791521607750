import React from "react";
import { ChevronDown } from "lucide-react";
import bannerImg from "../assets/images/banner7.jpg";
import Header from "../components/Header";
import ChinaRegions from "../components/MenuDestinations/MenuDestinations";
import Footer from "../components/Footer";
import ChinaMap from "../components/MapProvinces/ChinaMap";
import MapSingleCounty from "../components/MapSingleCounty";
import MapAllCounties from "../components/MapCounties";
import deqinCountyData from "../data/geojson_deqin.json";
import ShanghaiMap from "../components/ShanghaiMap";
import ChinaLeafletMap from "../components/ChinaLeafletMap";

const RegionsDemo = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Header />

      <div className="container mx-auto mt-8">
        {/* <ShanghaiMap /> */}
        <ChinaLeafletMap />
        <ChinaMap />
        <MapSingleCounty countyData={deqinCountyData} />
        <MapAllCounties />
      </div>
      <Footer />
    </div>
  );
};

export default RegionsDemo;
