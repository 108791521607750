import React from "react";
import { Link } from "react-router-dom";
import ChinaMap from "../MapProvinces/ChinaMap";
import { useHover } from "../../hooks/HoverContext";

const MenuRegions = () => {
  const { setHoveredProvince } = useHover();

  const regions = [
    {
      name: "Northwest China",
      chineseName: "西北地区",
      provinces: [
        {
          name: "Shaanxi",
          chineseName: "陕西",
          link: "/province/shaanxi",
        },
        { name: "Qinghai", chineseName: "青海", link: "/province/qinghai" },
        { name: "Gansu", chineseName: "甘肃", link: "/province/gansu" },
        { name: "Ningxia", chineseName: "宁夏", link: "/province/ningxia" },
        { name: "Xinjiang", chineseName: "新疆", link: "/province/xinjiang" },
      ],
      color: "border-purple-300",
    },
    {
      name: "North China",
      chineseName: "华北地区",
      provinces: [
        { name: "Beijing", chineseName: "北京", link: "/city/shanghai" },
        { name: "Tianjin", chineseName: "天津", link: "/city/tianjin" },
        { name: "Hebei", chineseName: "河北", link: "/province/hebei" },
        { name: "Shanxi", chineseName: "山西", link: "/province/shanxi" },
        {
          name: "Inner Mongolia",
          chineseName: "内蒙古",
          link: "/province/inner-mongolia",
        },
      ],
      color: "border-yellow-300",
    },
    {
      name: "Northeast China",
      chineseName: "东北地区",
      provinces: [
        { name: "Heilongjiang", chineseName: "黑龙江" },
        { name: "Jilin", chineseName: "吉林" },
        { name: "Liaoning", chineseName: "辽宁" },
      ],
      color: "border-blue-300",
    },
    {
      name: "Southwest China",
      chineseName: "西南地区",
      provinces: [
        { name: "Yunnan", chineseName: "云南", link: "/province/yunnan" },
        { name: "Sichuan", chineseName: "四川", link: "/province/sichuan" },
        { name: "Chongqing", chineseName: "重庆", link: "/city/chongqing" },
        { name: "Guizhou", chineseName: "贵州", link: "/province/guizhou" },
        { name: "Tibet", chineseName: "西藏", link: "/province/tibet" },
      ],
      color: "border-blue-300",
    },
    {
      name: "Central China",
      chineseName: "华中地区",
      provinces: [
        { name: "Hunan", chineseName: "湖南" },
        { name: "Hubei", chineseName: "湖北" },
        { name: "Henan", chineseName: "河南" },
      ],
      color: "border-blue-300",
    },
    {
      name: "East China",
      chineseName: "华东地区",
      provinces: [
        { name: "Shanghai", chineseName: "上海", link: "/province/shanghai" },
        { name: "Jiangsu", chineseName: "江苏", link: "/province/jiangsu" },
        { name: "Zhejiang", chineseName: "浙江", link: "/province/zhejiang" },
        { name: "Anhui", chineseName: "安徽", link: "/province/anhui" },
        { name: "Shandong", chineseName: "山东", link: "/province/shandong" },
        { name: "Jiangxi", chineseName: "江西", link: "/province/jiangxi" },
        { name: "Fujian", chineseName: "福建", link: "/province/fujian" },
      ],
      color: "border-green-300",
    },
    {
      name: "South China",
      chineseName: "华南地区",
      provinces: [
        { name: "Guangdong", chineseName: "广东" },
        { name: "Guangxi", chineseName: "广西" },
        { name: "Hainan", chineseName: "海南" },
        { name: "Hong Kong", chineseName: "香港" },
        { name: "Macao", chineseName: "澳门" },
        { name: "Taiwan", chineseName: "台湾" },
      ],
      color: "border-blue-300",
    },
  ];

  return (
    <div className="flex flex-col md:flex-row">
      <div className="w-full p-4 md:w-1/2">
        <div className="grid grid-cols-3 gap-6">
          {regions.map((region, index) => (
            <div key={index} className={`border-l-4 ${region.color} pl-3`}>
              <h3 className="mb-2 font-bold">{region.name}</h3>
              <ul>
                {region.provinces.map((province, prefIndex) => {
                  return (
                    <li key={prefIndex} className="mb-1 text-sm text-gray-600">
                      <Link
                        to={province.link}
                        onMouseEnter={() => {
                          console.log("onMouseEnter", province.name);
                          setHoveredProvince(province.name);
                        }}
                        onMouseLeave={() => {
                          console.log("onMouseLeave", province.name);
                          setHoveredProvince(null);
                        }}
                      >
                        {province.name} ({province.chineseName})
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full p-4 md:w-1/2" style={{ height: 100 }}>
        <ChinaMap showProvinces={false} />
      </div>
    </div>
  );
};

export default MenuRegions;
