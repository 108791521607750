import React from "react";
import { Clock, PenLine } from "lucide-react";
import { topDestinations } from "./MenuDestinations/SubmenuTopDestinations";

const FooterColumn = ({ title, items }) => (
  <div className="flex flex-col space-y-2">
    <h3 className="font-semibold text-gray-800">{title}</h3>
    {items.map((item, index) => (
      <a key={index} href="#" className="text-gray-600 hover:text-gray-800">
        {item}
      </a>
    ))}
  </div>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-100 pb-8">
      <div className="container mx-auto">
        {/* Main content */}
        {/* <div className="mb-12 grid grid-cols-6 gap-8">
          <FooterColumn
            title="Destinations"
            items={topDestinations.map((destination) => destination.name)}
          />
          <FooterColumn
            title="Plan a Trip"
            items={[
              "Before You Go",
              "Itineraries",
              "Tours",
              "Learn",
              "Transportation",
              "Accommodation",
              "Living in Japan",
            ]}
          />
        </div> */}

        {/* Bottom section */}
        <div className="flex flex-col items-center border-t border-gray-300 pt-6">
          <div className="flex space-x-4 text-sm text-gray-600">
            <a href="/about" className="hover:text-gray-800">
              About
            </a>
            <a href="#" className="hover:text-gray-800">
              Contact
            </a>
            <a href="#" className="hover:text-gray-800">
              Advertising
            </a>
            <a href="#" className="hover:text-gray-800">
              广告合作
            </a>
            <a href="#" className="hover:text-gray-800">
              Privacy & Terms
            </a>
          </div>
          <div className="mt-4 text-center text-sm text-gray-500">
            Copyright © {currentYear} superchinaguide.com All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
