import React, { useState } from "react";
import SubmenuRegions from "./MenuDestinations/SubmenuRegions";
import SubmenuTopDestinations from "./MenuDestinations/SubmenuTopDestinations";

const MenuShop = () => {
  const [activeSubmenu, setActiveSubmenu] = useState("topDestinations");

  const handleMouseEnter = (submenu) => {
    setActiveSubmenu(submenu);
  };

  const handleMouseLeave = () => {
    // setActiveSubmenu(null);
  };

  return (
    <div
      className="rounded-lg bg-white p-6 shadow-md"
      onMouseLeave={handleMouseLeave}
    >
      <div className="mb-4 flex items-center justify-between">
        <div id="nav-items" className="flex space-x-4">
          <span
            className="cursor-pointer font-semibold"
            onMouseEnter={() => handleMouseEnter("topDestinations")}
          >
            Guide Books
          </span>
          <span
            className="cursor-pointer font-semibold"
            onMouseEnter={() => handleMouseEnter("regions")}
          >
            Services
          </span>
          <span
            className="cursor-pointer font-semibold text-black"
            onMouseEnter={() => handleMouseEnter("provinces")}
          >
            Local Specialties
          </span>
        </div>
      </div>
      {activeSubmenu === "topDestinations" && <SubmenuTopDestinations />}
      {activeSubmenu === "regions" && <SubmenuRegions />}
    </div>
  );
};

export default MenuShop;
