import React from "react";
import beijingImg from "../../assets/images/beijing1.jpg";
import chengduImg from "../../assets/images/chengdu2.jpg";
import chongqingImg from "../../assets/images/chongqing1.jpg";
import hongkongImg from "../../assets/images/hongkong1.jpg";
import snowImg from "../../assets/images/snowboard1.jpg";
import wildlifeImg from "../../assets/images/lizard1.jpg";
import hikingImg from "../../assets/images/hiking4.jpg";
import desertImg from "../../assets/images/desert1sm.jpg";
import xianImg from "../../assets/images/xian1.jpg";
import roadtripImg from "../../assets/images/drive1.jpg";
import roadtripImg2 from "../../assets/images/drive2.jpg";
import { Link } from "react-router-dom";

export const natureCategories = [
  {
    name: "Hiking",
    chineseName: "徒步",
    img: hikingImg,
  },
  {
    name: "Roadtrips",
    chineseName: "自驾旅行",
    img: roadtripImg,
  },
  {
    name: "Snow Sports",
    chineseName: "冰雪运动",
    img: snowImg,
  },
  {
    name: "Rock Climbing",
    chineseName: "攀岩",
    img: xianImg,
  },
  {
    name: "Photography",
    chineseName: "摄影",
    img: roadtripImg2,
  },
  {
    name: "Wildlife",
    chineseName: "野生动物",
    img: wildlifeImg,
  },
  {
    name: "Deserts",
    chineseName: "沙漠",
    img: desertImg,
  },
  {
    name: "National Parks",
    chineseName: "国家公园",
    img: hongkongImg,
  },
];

const TopDestinationCard = ({ destination }) => {
  return (
    <div key={destination.name} className="relative rounded-lg border p-4">
      <Link to={`/city/${destination.name}`}>
        <img
          src={`${destination.img}`}
          alt={destination.name}
          className="h-auto w-full rounded-lg"
        />
      </Link>
      <div className="absolute bottom-0 left-0 right-0 p-6 text-center text-white">
        <h3 className="text-lg font-semibold">{destination.name}</h3>
        {/* <p className="text-sm">{destination.chineseName}</p> */}
      </div>
    </div>
  );
};

const SubmenuNature = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {natureCategories.map((destination) => (
          <TopDestinationCard destination={destination} />
        ))}
      </div>
      <div className="mt-4">
        <Link>View all cities</Link>
      </div>
    </div>
  );
};

export default SubmenuNature;
