import { Link } from "react-router-dom";

const AttractionCard = ({ link, name, desc, img }) => {
  return (
    <div className="max-w-xs overflow-hidden rounded-lg bg-white shadow-lg">
      <Link to={link}>
        <img className="h-44 w-full object-cover" src={img} alt={name} />
        <div className="px-4 py-3">
          <h2 className="font-semibold text-gray-800">{name}</h2>
          <p className="text-sm text-gray-600">{desc}</p>
        </div>
      </Link>
    </div>
  );
};

export default AttractionCard;
